import { useState } from "react";

/**
 * @description used to manipulate local storage with inserted key and value pairs that is
 * able to be accessed throughout the whole appliction
 * @param key the local storage key
 * @param value the local storage value
 */
const useLocalStorage = (key: string, value?: string) => {
  // pass initial state function to useState so the logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : value;
    } catch (error: any) {
      // catch edgecases and problems with the value
      return value;
    }
  });
  /**
   * wrapped version of useStates setter that persists the new value to local storage
   */
  const setValue = (value: any) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error: any) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
};

export default useLocalStorage;
