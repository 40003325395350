import * as React from "react";
import Typography from "@material-ui/core/Typography";

interface TitleProps {
  children?: React.ReactNode;
}

export default function Title(props: TitleProps) {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom align="center">
      {props.children}
    </Typography>
  );
}
