import React, { useContext, useState } from "react";

//Context
import AdminContext from "../../context/admin/adminContext";

//Diaglogs
import { DialogActions, DialogContent, DialogTitle, Button, TextField } from "@material-ui/core";

interface Props {
  handleClose: any;
}

const NewInsuranceModal: React.FC<Props> = ({ handleClose }) => {
  const adminContext = useContext(AdminContext);
  const { createInsurance } = adminContext;

  const [formData, setFormData] = useState({
    name: "",
    logoURL: "",
  });

  const handleChange = (e: any) => {
    if (e.target) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    createInsurance(formData);
    handleClose();
  };

  return (
    <form onSubmit={(e) => onSubmit(e)} method="POST">
      <DialogTitle id="form-dialog-title">Neue Insurance</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          required
          name="name"
          label="Insurance name"
          type="text"
          fullWidth
          onChange={(e) => handleChange(e)}
        />
        <TextField
          margin="dense"
          required
          name="logoURL"
          label="Company logo URL"
          type="text"
          fullWidth
          onChange={(e) => handleChange(e)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Abbrechen
        </Button>
        <Button color="primary" type="submit">
          Erstellen
        </Button>
      </DialogActions>
    </form>
  );
};

export default NewInsuranceModal;
