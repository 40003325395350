import { format, isValid, addMonths, subMonths } from "date-fns";

function formatDate(date: Date): string {
  return format(date, "dd.MM.yyyy");
}

function isValidDate(date: Date) {
  return isValid(date);
}

function dateFactory(months: number, future: boolean) {
  if (future) {
    return addMonths(new Date(), months);
  }

  return subMonths(new Date(), months);
}

export { formatDate, isValidDate, dateFactory };
