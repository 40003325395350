import React from "react";
// Material UI and styling
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// Components
import Insurances from "./Insurances";
import Contracts from "./Contracts";
import Variants from "./Variants";

const AdminDashboard: React.FC = () => {
  const classes = useStyles();

  return (
    <div>
      <main className={classes.content}>
        <h1 className={classes.heading}>Admin Dashboard</h1>

        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Insurances />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Contracts />
            </Paper>
          </Grid>

          <Grid item xs={12} md={10}>
            <Paper className={classes.paper}>
              <Variants />
            </Paper>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "center",
    fontSize: "3.25rem",
    fontWeight: 500,
    marginBottom: "3rem",
  },
  content: {
    flexGrow: 1,
    maxWidth: "140rem",
    margin: "4rem auto",
  },
  container: {
    padding: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default AdminDashboard;
