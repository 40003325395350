import React, { Fragment, useEffect, useState, useCallback } from "react";
// Material UI and styling
import styled from "styled-components";
import axios from "axios";
import { set, get } from "lodash";
import { useParams } from "react-router-dom";
import { Button, Grid, Paper, TextField, IconButton, Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import axiosConfig from "../../../utils/axiosConfig";
import { useSnackBars } from "../../../utils/hooks/snackbar/useSnackbar";
import getAxiosJsonConfig from "../../../utils/axiosJsonConfig";

const { REACT_APP_SERVER_URI } = process.env;

interface RouterType {
  initialKey: string;
}

const PdfEditor = () => {
  const classes = useStyles();
  const [jsonState, setJsonState] = useState<any>({});
  const [currentKey, setCurrentKey] = useState<any>("");
  const [open, setOpen] = React.useState(false);
  // get the key from react router
  const { initialKey } = useParams<RouterType>();

  const { addAlert } = useSnackBars();

  useEffect(() => {
    // fetch json from server
    async function fetchPdfJson() {
      try {
        // fetch data based on the initial key
        const { data } = await axios.get(`${REACT_APP_SERVER_URI}/pdf/json`, axiosConfig());
        setJsonState(data);
      } catch (error: any) {
        addAlert?.({ message: error.response.data.message, type: "error" });
      }
    }
    fetchPdfJson();
  }, []); // eslint-disable-line

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const deepUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
      const objectPath = key;
      const stateCopy = jsonState;
      set(stateCopy, objectPath, e.target.value);
      setJsonState((prev: any) => {
        return {
          ...prev,
          ...stateCopy,
        };
      });
    },
    [get(jsonState, currentKey)] //eslint-disable-line
  );

  const renderInputFields = useCallback(
    (data: any, parentKey: string): React.ReactNode[] => {
      const elements: any = Object.keys(data).map((key: string) => {
        if (typeof data[key] === "object") {
          return [
            <Title key={Math.random()}>{key}</Title>,
            ...renderInputFields(data[key], `${parentKey}.${key}`),
          ];
        }
        return (
          <TextField
            className={classes.textField}
            variant="outlined"
            multiline
            key={`${parentKey}_${key}`}
            label={key}
            type="text"
            value={data[key]}
            onFocus={(e) => setCurrentKey(`${parentKey}.${key}`)}
            onChange={(e) => deepUpdate(e, `${parentKey}.${key}`)}
          />
        );
      });
      return elements;
    },
    [get(jsonState, currentKey)] //eslint-disable-line
  );

  const submitFields = async () => {
    try {
      const response = await axios.put(
        `${REACT_APP_SERVER_URI}/pdf/json`,
        jsonState,
        getAxiosJsonConfig()
      );
      addAlert?.({ message: response.data.message, type: "success" });
    } catch (error: any) {
      addAlert?.({ message: error.response.data.message, type: "error" });
    }
  };

  return (
    <FlexContainer>
      <main className={classes.content}>
        <h1 className={classes.heading}>PDF Dashboard</h1>

        <Fragment>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={12}>
              {jsonState &&
                Object.entries(jsonState).map(([key, val]) => {
                  if (key !== initialKey) return null;
                  return (
                    <Paper key={key} className={classes.paper}>
                      {renderInputFields(jsonState[key], key)}
                    </Paper>
                  );
                })}
            </Grid>
            <Button variant="contained" color="primary" onClick={() => submitFields()}>
              Änderung speichern
            </Button>
          </Grid>
          <br />
        </Fragment>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          className={classes.snackbar}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Successfully saved"
          action={
            <React.Fragment>
              <Button color="secondary" size="small" onClick={handleClose}>
                OKAY
              </Button>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </main>
    </FlexContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "center",
    fontSize: "4rem",
    marginBottom: "3rem",
  },
  content: {
    width: "80vw",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
  },
  textField: {
    margin: ".75rem 0",
  },
  snackbar: {
    "& .MuiSnackbarContent-root": {
      background: "#1e7398",
    },
  },
}));

const FlexContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 3rem;
  border-bottom: 1px solid #222;
  margin: 2rem 0;
`;

export default PdfEditor;
