import React, { useContext, useEffect, useState, Fragment } from "react";
import FormContext from "../../context/form/formContext";
import { Card, CardHeader, CardContent, Button, CardActions } from "@material-ui/core";
import { getAccidentTableSum } from "../../utils/services/service-calculate";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";

const PriceDisplayPanel = () => {
  const formContext = useContext(FormContext);
  const { insurances, run, contractDuration, displayedVariantID, setAnnualFee, step, setStep } =
    formContext;

  const [sums, setSums] = useState<Array<number>>([]);

  const classes = useStyles();

  const handleClick = (): void => {
    if (typeof step !== "undefined") {
      setStep?.(step + 1);
    }
  };

  useEffect(() => {
    if (insurances && insurances.length > 0 && displayedVariantID) {
      const currentSum = getAccidentTableSum(insurances, displayedVariantID, contractDuration);
      setSums(currentSum);
      setAnnualFee?.(currentSum?.[0]);
    }
  }, [insurances, run, displayedVariantID, contractDuration]); //eslint-disable-line

  return (
    <Card square className={classes.priceCard}>
      <CardHeader className={classes.priceTitle} title="Ihr Jahresbeitrag" />
      <CardContent>
        <h3 className={classes.price}>
          {sums &&
            sums.map((sum, index) => (
              <Fragment key={index}>
                {" "}
                <p>{sum?.toFixed(2)?.replace(".", ",")}&euro;</p>
                <p style={{ fontSize: "1.3rem" }}>Inklusive 19% Vers.Steuer</p>{" "}
              </Fragment>
            ))}
        </h3>
      </CardContent>
      <CardActions className={classes.priceActions}>
        <PriceButton onClick={() => handleClick()} variant="contained">
          Angebot holen!
        </PriceButton>
      </CardActions>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  priceCard: {
    background: theme.palette.primary.light,
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  priceTitle: {
    color: "white",
    fontWeight: 500,
  },
  priceActions: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  price: {
    fontSize: "3rem",
    fontWeight: 500,
  },
}));

const PriceButton = withStyles((theme: Theme) => ({
  root: {
    color: "#333",
    backgroundColor: "#f2f2f2",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
}))(Button);

export default PriceDisplayPanel;
