import React from "react";
import styled from "styled-components";

const ThankyouHero = () => {
  return (
    <Container>
      <HeroTitle>Vielen Dank für Ihr Vertrauen</HeroTitle>
    </Container>
  );
};

const Container = styled.section`
  height: 30vh;
  background: var(--color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://res.cloudinary.com/syzdevacc/image/upload/f_auto,q_auto:good/v1636672374/Gutzweiler/start-slider-motorflugzeug-d-mz-pixabay_vnnvbf.jpg");
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center center;
  margin-bottom: 5rem;
`;

const HeroTitle = styled.h1`
  text-align: center;
  font-size: calc(1rem + 2vw);
  font-weight: 700;
  letter-spacing: 2px;
  color: white;
  text-transform: uppercase;
  text-shadow: 1px 2px 5px #111d;
  @media screen and (min-width: 1280px) {
    font-size: 3.75rem;
  }
`;

export { ThankyouHero };
