import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Container,
  CircularProgress,
  CardContent,
  Typography,
} from "@material-ui/core";
import axios from "axios";

import axiosConfig from "../../../utils/axiosConfig";
import { useSnackBars } from "../../../utils/hooks/snackbar/useSnackbar";
import { MainTitle } from "../../styled/Typography";
import { Centered } from "../../styled/Utility";
import { Link } from "react-router-dom";
const { REACT_APP_SERVER_URI } = process.env;

const PdfSelection = () => {
  const [jsonState, setJsonState] = useState<any>({});
  const [{ loading }, setState] = useState<any>({
    loading: true,
  });

  const { addAlert } = useSnackBars();

  useEffect(() => {
    // fetch json from server
    async function fetchPdfJson() {
      try {
        const { data } = await axios.get(`${REACT_APP_SERVER_URI}/pdf/json`, axiosConfig());
        setJsonState(data);
        setState((prev: any) => ({ ...prev, loading: false }));
      } catch (error: any) {
        addAlert?.({ message: error.response.data.message, type: "error" });
      }
    }
    fetchPdfJson();
  }, []); // eslint-disable-line

  if (loading)
    return (
      <Centered>
        <CircularProgress color="primary" />
      </Centered>
    );
  return (
    <Container style={{ paddingBottom: "10rem" }}>
      <MainTitle>PDF wählen</MainTitle>
      <Grid container justifyContent="center" alignItems="center" component="main" spacing={5}>
        <Grid xs={12} item>
          <Grid container spacing={5} justifyContent="center" alignItems="center">
            {jsonState &&
              Object.entries(jsonState).map(([key, val]) => {
                return (
                  <Grid item>
                    <Link to={`/admin/pdf/editor/${key}`}>
                      <Card key={key}>
                        <CardContent>
                          <Typography variant="h6" component="h2">
                            {key}
                          </Typography>
                          <Typography variant="body1" component="p">
                            Klick to edit the pdf content
                          </Typography>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export { PdfSelection };
