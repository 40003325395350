import React, { useContext, useState } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import FormContext from "../../../context/form/formContext";
import PersonalData from "./steps/PersonalData";
import RateData from "./steps/RateData";
import EnterAgreement from "../../modals/EnterAgreement";
import Summary from "./steps/Summary";
import { Centered } from "../../styled/Utility";

/**
 * This component is a wrapper for all table steps.
 * It uses the context to determine, which step to show
 */

const CalculatorContainer = () => {
  const formContext = useContext(FormContext);
  const { step, insurances, loading } = formContext;
  const [open, setOpen] = useState(true);
  const handleOpen = (open: boolean) => {
    setOpen(open);
  };

  return (
    <>
      <EnterAgreement handleOpen={handleOpen} open={open} />
      <Grid
        container
        component={"div"}
        alignItems="center"
        justifyContent="center"
        style={{ margin: "4rem 0" }}
      >
        <Grid className="list--sh" item style={{ flex: 1 }}>
          {loading ? (
            <Centered>
              <p>Einen Moment - wir senden Ihnen alle Dokumente zu</p>
              <br />
              <CircularProgress color="primary" />
            </Centered>
          ) : (
            <>
              {step === 0 && insurances && <RateData />}
              {step === 1 && <PersonalData />}
              {step === 2 && <Summary />}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CalculatorContainer;
