import React from "react";
import { Grid, Container, Paper, Button } from "@material-ui/core";
import styled from "styled-components";
import { ThankyouHero } from "./Hero";
import { Link } from "react-router-dom";

const Thankyou = () => {
  return (
    <>
      <ThankyouHero />
      <Container style={{ paddingBottom: "10rem" }}>
        <Grid container justifyContent="center" alignItems="center" component="main" spacing={5}>
          <Grid xs={12} item>
            <Paper style={{ padding: "2rem" }}>
              <MainTitle>Wir haben Ihnen eine Nachricht gesendet</MainTitle>
              <TextContent>
                Sehen Sie bitte in Ihrem Postfach nach (auch im Spam), prüfen Sie die Unterlagen,
                welche wir Ihnen zugesandt haben und senden Sie uns das unterschriebene Angebot
                zurück.
              </TextContent>
            </Paper>
          </Grid>
          <HomeLink to="/">
            <Button color="primary" variant="contained">
              Zurück zur Homepage
            </Button>
          </HomeLink>
        </Grid>
      </Container>
    </>
  );
};

const MainTitle = styled.h1`
  text-align: center;
  font-size: calc(1vw + 1.25rem);
  font-weight: 300;
  margin: 5rem 0;
`;

const TextContent = styled.p`
  text-align: center;
  font-weight: 300;
`;

const HomeLink = styled(Link)`
  text-decoration: none !important;
  color: white;
`;
export default Thankyou;
