import React, { createContext, useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

interface Alert {
  type: "info" | "error" | "success";
  message: string;
}

type SetAlertFunction<T> = (arg0: T) => void;

type SnackbarContext = {
  addAlert: SetAlertFunction<Alert>;
};

// @ts-ignore
export const SnackBarContext = createContext<SnackbarContext>();

export function SnackBarProvider({ children }: any) {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const classes = useStyles();

  const activeAlertIds = alerts.map((alert) => alert.message);

  useEffect(() => {
    if (activeAlertIds.length > 0) {
      const timer = setTimeout(
        () => setAlerts((alerts) => alerts.slice(0, alerts.length - 1)),
        3000
      );
      return () => clearTimeout(timer);
    }
  }, [activeAlertIds]);

  // @ts-ignore
  const addAlert = (alert: Alert) => setAlerts((alerts) => [alert, ...alerts]);

  return (
    <SnackBarContext.Provider
      value={{
        addAlert,
      }}
    >
      {children}
      {alerts?.map((alert, i) => {
        switch (alert.type) {
          case "info": {
            return (
              <Snackbar
                className={classes.info}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={true}
                key={i}
                message={alert.message}
              />
            );
          }
          case "error": {
            return (
              <Snackbar
                className={classes.error}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={true}
                key={i}
                message={alert.message}
              />
            );
          }
          case "success": {
            return (
              <Snackbar
                className={classes.success}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={true}
                key={i}
                message={alert.message}
              />
            );
          }
          default: {
            return (
              <Snackbar
                className={classes.info}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={true}
                key={i}
                message={alert.message}
              />
            );
          }
        }
      })}
    </SnackBarContext.Provider>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    color: "white",
    "& .MuiSnackbarContent-root": {
      background: "#1e7398",
    },
  },
  error: {
    color: "white",
    "& .MuiSnackbarContent-root": {
      background: "#d53e4a",
    },
  },
  success: {
    color: "white",
    "& .MuiSnackbarContent-root": {
      background: "#166806",
    },
  },
}));
