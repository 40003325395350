export enum Options {
  Unknown = "",
  Progression = "Progression",
  Grundsumme = "Grundsumme Invalidität",
  Todesfall = "Todesfall",
  Flugrisiko = "Flugrisiko",
  Gesundheitspruefung = "Gesundheitsprüfung",
  Weltweit = "Weltweiter Versicherungsschutz",
  Altersbeschraenkung = "Altersbeschränkung",
  Kunstflug = "Kunstflug",
  Gueltigkeit_24 = "Gültigkeit",
  Uebergangsleistung = "Verbesserte Übergangsleistung",
  Sofortleistung_schwerverletzung = "Sofortleistung Schwerverletzung",
  Bergungskosten = "Bergungskosten",
  Kosmetische_operationen = "Kosmetische Operationen",
  Sofortleistung_knochen = "Sofortleistung Knochenbrüche",
  Sofortleistung_koma = "Sofortleistung Koma",
  Umbaukosten = "Übernahme Umbaukosten",
  Psychologische_betreuung = "Psychologische Betreuung",
  Grabpflege = "Grabpflege",
  Praxisgebuehr_notfall = "Praxisgebühr Notfall",
  Erweiterung_unfallbegriff = "Erweiterung Unfallbegriff",
  Schlaganfall = "Einschluss Schlaganfall",
  Leistungserweiterung_invaliditaet = "Leistungserweiterung Invalidität",
  Gliedertaxe = "Verbesserte Gliedertaxe",
  Eintrittsfrist = "Verlängerte Eintrittsfrist",
  Meldefrist_tod = "Verlängerte Meldefrist",
  Bedingungsverbesserungen = "Bedingungsverbesserungen",
}

export enum Contracts {
  Unfallversicherung = "Unfallversicherung",
  Lebensversicheurng = "Lebensversicheurng",
  Pilotenversicherung = "Pilotenversicherung",
  Flugzeugversicherung = "Flugzeugversicherung",
}

export enum CellTypes {
  Information = "information",
  Fix = "fix",
  Checkbox = "checkbox",
  Dropdown = "dropdown",
  Price = "price",
}

export enum ContractVariantsAccident {
  Basis = "Basis",
  Top = "Top",
}
