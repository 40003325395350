import React, { Fragment, useState, useEffect, useContext } from "react";
// Material UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Dialog, Button } from "@material-ui/core";
// Icons
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// Styling
import Title from "./Title";
import { makeStyles } from "@material-ui/core/styles";
// Context
import AdminContext from "../../context/admin/adminContext";
// Modals
import NewContractModal from "./NewContractModal";
import EditContractModal from "./EditContractModal";
// Utlity
import confirmDelete from "../../utils/confirmDelete";

const Contracts: React.FC = () => {
  const classes = useStyles();

  const adminContext = useContext(AdminContext);
  const { contracts, getContracts, currentInsurance, getCurrentContract, deleteContract } =
    adminContext;

  const [newContractModal, setNewContractModal] = useState(false);
  const [editContractModal, setEditContractModal] = useState(false);

  const setCurrentContractClick = (_id: String) => {
    getCurrentContract(_id);
  };

  const editContract = (_id: String) => {
    getCurrentContract(_id);
    setEditContractModal(true);
  };

  const removeContract = (_id: String) => {
    const confirm = confirmDelete();

    if (confirm) {
      deleteContract(_id);
    }
  };

  const handleClose = () => {
    setNewContractModal(false);
    setEditContractModal(false);
  };

  useEffect(() => {
    if (currentInsurance?._id !== undefined) {
      getContracts(currentInsurance._id);
    }
    return;
  }, [currentInsurance]); //eslint-disable-line

  return (
    <Fragment>
      <Title>Verträge</Title>
      <Table size="small">
        {currentInsurance?._id !== undefined ? (
          contracts?.length === 0 ? (
            <h2>No contracts found for the selected insurance - {currentInsurance?.name}</h2>
          ) : (
            <Fragment>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>S.No.</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <br />
              </TableHead>
              <TableBody>
                {contracts?.map((contract, index) => {
                  return (
                    <Fragment>
                      <TableRow key={index}>
                        <TableCell style={{ textAlign: "center" }}>{index + 1}</TableCell>
                        <TableCell
                          className={classes.nameField}
                          onClick={() => setCurrentContractClick(contract._id)}
                        >
                          {contract.name}
                        </TableCell>

                        <TableCell>
                          <EditIcon
                            className={classes.icon}
                            onClick={() => editContract(contract._id)}
                          />
                        </TableCell>
                        <TableCell>
                          <DeleteIcon
                            className={classes.icon}
                            onClick={() => removeContract(contract._id)}
                          />
                        </TableCell>
                      </TableRow>
                      <br />
                    </Fragment>
                  );
                })}
              </TableBody>
            </Fragment>
          )
        ) : (
          <h2 className={classes.noResourceMessage}>
            Bitte Klicke auf eine Versicherung, um die Verträge anzuzeigen
          </h2>
        )}
      </Table>

      {currentInsurance?._id !== undefined && (
        <Fragment>
          <Button
            style={{ margin: "2rem" }}
            variant="contained"
            color="primary"
            onClick={() => setNewContractModal(true)}
          >
            Neuen Vertrag anlegen
          </Button>
          <Dialog
            open={newContractModal}
            onClose={handleClose}
            aria-labelledby="Open Contract Form"
          >
            <NewContractModal handleClose={handleClose} />
          </Dialog>
          <Dialog
            open={editContractModal}
            onClose={handleClose}
            aria-labelledby="Open Edit Contract Form"
          >
            <EditContractModal handleClose={handleClose} />
          </Dialog>
        </Fragment>
      )}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  noResourceMessage: {
    textAlign: "center",
    margin: "4rem 0",
  },
  nameField: {
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#f2f2f2",
      cursor: "pointer",
    },
  },
  icon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default Contracts;
