import { Condition } from "../definitions";
/**
 * @param object condition candidate
 */
export function isCondition(object: any): object is Condition {
  return "_id" in object && "name" in object;
}

export function isError(error: any): error is Error {
  return error.code && error.message;
}
