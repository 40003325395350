import React from "react";
import { TableCell } from "@material-ui/core";
import { Condition } from "../../../../definitions";

const DescriptionCell = (cell: Condition) => {
  const { information } = cell;

  return (
    <TableCell>
      <span>{`${information}`}</span>
    </TableCell>
  );
};

export default DescriptionCell;
