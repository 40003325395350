import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { ExitToApp } from "@material-ui/icons";
import { AppBar, Toolbar, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";

import Logo from "../../assets/img/GUTZWEILER-logo-r-ohneSlogan-614x156.png";
import AuthContext from "../../context/auth/authContext";
import AppContext from "../../context/app/appContext";
import styled from "styled-components";

const Header = () => {
  const classes = useStyles();

  const appContext = useContext(AppContext);
  const { toggleDrawer } = appContext;

  const authContext = useContext(AuthContext);
  const { isAuthenticated, logout } = authContext;

  useEffect(() => {}, [isAuthenticated]);

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar}>
      <NavigationLeft>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => toggleDrawer?.()}
        >
          <MenuIcon />
        </IconButton>

        {!!isAuthenticated && (
          <Link to="/admin" style={{ color: "white" }} onClick={() => logout()}>
            <ExitToApp />
          </Link>
        )}
        </NavigationLeft>
      <Branding>
        <img src={Logo} alt="Logo" />
      </Branding>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar:{
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }
}));

const Branding = styled.div`
  filter: brightness(10);
  width: 14rem;

  & img {
    width: 100%;
  }
`;

const NavigationLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Header;
