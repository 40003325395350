import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TextField, InputAdornment, Paper, Button, CircularProgress } from "@material-ui/core";

import AccountCircle from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";

import FormContext from "../../../../context/form/formContext";
import { ValidStateType } from "../../../../definitions/interfaces";
import { validateInstructorInsuranceForm } from "../../../../utils/services/service-validator";
import { useSnackBars } from "../../../../utils/hooks/snackbar/useSnackbar";
import { Centered } from "../../../styled/Utility";

const PersonalDataReduced = () => {
  const classes = useStyles();

  const formContext = useContext(FormContext);
  const history = useHistory();
  const { addAlert } = useSnackBars();

  const {
    setValue,
    email,
    firstName,
    lastName,
    sendInitialEmail,
    submitForm,
    loading,
    setLoading,
  } = formContext;

  const [formState, setFormState] = useState({
    firstName,
    lastName,
    email,
    confirmEmail: "",
  });

  const [{ firstNameValid, lastNameValid, emailValid }, setValidateState] = useState<
    Partial<ValidStateType>
  >({
    firstNameValid: null,
    lastNameValid: null,
    emailValid: null,
  });

  useEffect(() => {
    setValue?.(formState);
  }, [formState]); // eslint-disable-line

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target?.name) {
      const { name, value } = e.target;

      setFormState((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      const formValidation = validateInstructorInsuranceForm(formContext);

      if (formValidation.isError) {
        throw new Error(formValidation.message || "Ihre Formulareingabe ist ungültig");
      }

      // set loading
      setLoading?.(true);

      // first contact information
      if (email) {
        await sendInitialEmail?.(email);
      }

      await submitForm?.({}, "instructor");

      setLoading?.(false);

      history.push("/done");
    } catch (error: any) {
      addAlert({ type: "error", message: error.message });
    } finally {
      setLoading?.(false);
    }
  };

  const validateField = (fieldName: string, value: string) => {
    if (!value)
      setValidateState((prev) => {
        return {
          ...prev,
          [fieldName]: false,
        };
      });

    switch (fieldName) {
      case "firstName": {
        setValidateState((prev) => {
          return {
            ...prev,
            firstNameValid: value.length > 0,
          };
        });
        break;
      }

      case "lastName": {
        setValidateState((prev) => {
          return {
            ...prev,
            lastNameValid: value.length > 0,
          };
        });
        break;
      }

      case "confirmEmail": {
        setValidateState((prev) => {
          return {
            ...prev,
            emailValid: value === formState.email,
          };
        });

        break;
      }

      default: {
        return;
      }
    }
  };

  return (
    <FormContainer onSubmit={(e) => handleSubmit(e)}>
      <Paper className={classes.paper}>
        <FormRow>
          <TextField
            error={typeof firstNameValid !== "boolean" ? false : !firstNameValid}
            onChange={(e) => handleChange(e)}
            className={classes.input}
            label="Vorname"
            fullWidth
            value={firstName}
            name="firstName"
            onBlur={(e) => validateField(e.target.name, e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            error={typeof lastNameValid !== "boolean" ? false : !lastNameValid}
            onChange={(e) => handleChange(e)}
            className={classes.input}
            fullWidth
            label="Nachname"
            value={lastName}
            name="lastName"
            onBlur={(e) => validateField(e.target.name, e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
        </FormRow>
        <FormRow>
          <TextField
            error={typeof emailValid !== "boolean" ? false : !emailValid}
            helperText={emailValid === false ? "Email Addressen stimmen nicht überein" : ""}
            onChange={(e) => handleChange(e)}
            fullWidth
            className={classes.input}
            label="E-Mail"
            type="email"
            name="email"
            onBlur={(e) => validateField(e.target.name, e.target.value)}
            value={email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormRow>
        <FormRow>
          <TextField
            error={typeof emailValid !== "boolean" ? false : !emailValid}
            helperText={emailValid === false ? "Email Addressen stimmen nicht überein" : ""}
            onChange={(e) => {
              handleChange(e);
              validateField("confirmEmail", e.target.value);
            }}
            fullWidth
            className={classes.input}
            label="E-Mail bestätigen"
            type="email"
            name="confirmEmail"
            value={formState.confirmEmail}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormRow>
        <FormRow>
          <Button variant="contained" color="primary" type="submit" disabled={loading}>
            Anfragen
          </Button>
        </FormRow>
        {loading && (
          <Centered>
            <CircularProgress color="primary" />
          </Centered>
        )}
      </Paper>
    </FormContainer>
  );
};

const FormContainer = styled.form`
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
`;

const FormRow = styled.div`
  display: flex;
  margin: 0.5rem 0;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      padding: "1rem",
    },
    paper: {
      padding: "1rem",
    },
  })
);

export { PersonalDataReduced };
