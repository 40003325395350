import React from "react";
import { Paper, Chip, Avatar } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";

const ConditionPanel = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.container} square>
      <Chip
        className={classes.chip}
        avatar={<Avatar>B</Avatar>}
        label="Bedingungen Basis"
        clickable
        component="a"
        href="https://luftfahrtversicherung24.de/assets/uploads/Bedingungspaket-Unfall-Basis.pdf"
        target="_blank"
        rel="nofollow noreferrer"
        color="primary"
        deleteIcon={<DoneIcon />}
      />
      <Chip
        className={classes.chip}
        avatar={<Avatar>T</Avatar>}
        label="Bedingungen Top"
        clickable
        component="a"
        href="https://luftfahrtversicherung24.de/assets/uploads/Bedingungspaket-Unfall-Top.pdf"
        target="_blank"
        rel="nofollow noreferrer"
        color="secondary"
        deleteIcon={<DoneIcon />}
      />
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    chip: {
      "&:hover": {
        color: "white",
        textDecoration: "none",
      },
    },
    container: {
      padding: "1rem 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "2rem",
    },
  })
);

export default ConditionPanel;
