import React, { useContext, useEffect } from "react";
import { TableCell, TextField, MenuItem } from "@material-ui/core";
import { Condition, Options } from "../../../../definitions";
import FormContext from "../../../../context/form/formContext";

const DropdownCell = (cell: Condition) => {
  const { _id, name, price, options } = cell;

  const formContext = useContext(FormContext);

  const { updateDropdownRow, underlying, deathBenefit } = formContext;

  useEffect(() => {
    //preselect values
    if (options && name) {
      let payLoad = { name, value: options?.[0] };

      updateDropdownRow?.(payLoad);
    }
  }, [name, options]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * @description pre check of values as the different dropdowns have conditions how
   * and when they can be selected or not.
   * @param e
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (name === Options.Todesfall) {
      if (underlying && parseInt(e.target.value) > underlying) {
        window.alert(
          "Sie können die Todessumme nicht höher als die Grundsumme Invalidität festlegen"
        );
        return;
      }
    }

    if (name === Options.Grundsumme) {
      if (deathBenefit && deathBenefit > parseInt(e.target.value)) {
        window.alert(
          "Sie können die Todessumme nicht höher als die Grundsumme Invalidität festlegen"
        );
        return;
      }
    }

    if (updateDropdownRow) {
      updateDropdownRow(e.target);
    }
  };

  return (
    <TableCell>
      <TextField
        id={_id}
        variant="outlined"
        value={price}
        select
        fullWidth
        onChange={(e) => handleChange(e)}
        name={name}
      >
        {options &&
          options?.map((option) => (
            <MenuItem key={option} value={option}>
              {option.toLocaleString("de-DE")}
            </MenuItem>
          ))}
      </TextField>
    </TableCell>
  );
};

export default DropdownCell;
