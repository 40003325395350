import { Condition, Variants } from "../definitions";
import { generateBaseCell } from "./structures/object.baseCell";

/**
 * @description creates an array of rates that will be used to create rows afterwards
 * @param insurances
 * @param displayedVariantID
 */
export const createRateArray = (insurances: object, displayedVariantID: string): Array<any> => {
  let val = Object.values(insurances).map((insurance) => {
    // only for current tab
    const { variants } = insurance;

    const match = variants.find((variant: Variants) => variant._id === displayedVariantID);

    const { conditions } = match;

    const conditionsArray = [];

    if (conditions) {
      conditionsArray.push(conditions);
    }

    return conditionsArray;
  });

  const [rateArray] = val;
  return rateArray;
};

/**
 * @description: to render out data in the table the inserted data has to be transformed multiple times.
 * the outgoung structure is lik [string, number, number] so the value can be rendered for its variant column
 * @param data
 * @param rateName
 */
export const createSingleRow = (data: Array<Array<Condition>>, rateName: string): any => {
  const variants: Array<any> = data;
  const prices: Array<Condition | null> = [];
  let description = "";

  /**
   * to get the description value in the table we determine if the description exists, otherwise display the name
   */
  let flat = variants?.flatMap((rate: Array<Condition>) => {
    return Object.values(rate).filter((set: Condition) => {
      if (set.name === rateName) {
        description = set.description;
        return set.name === rateName;
      } else return false;
    });
  });

  flat.forEach((val: Condition) => {
    prices.push(val);
  });

  // fixed empty cells if rate missing
  while (prices.length < variants.length) {
    prices.push(generateBaseCell());
  }
  return description ? [description, ...prices] : [rateName, ...prices];
};
