/*
 *  This is the main app components and integrates all logic in the dashboard.  
 *
 @description: Structure of this component will be:
 *  1) Stepper
 *  2) Tabs for each insurance company
 *  3) custom input fields, connected to the form level state (context)
 */

import React, { useContext, useEffect } from "react";
import ProgressStepper from "./ProgressStepper";
import CalculatorContainer from "./content/CalculatorContainer";

import AppContext from "../../context/app/appContext";
import FormContext from "../../context/form/formContext";

const Calculator = () => {
  const appContext = useContext(AppContext);
  const { currentContract } = appContext;

  const formContext = useContext(FormContext);
  const { setInsuranceData } = formContext;

  useEffect(() => {
    // fetch contract data
    setInsuranceData?.(currentContract);
  }, [currentContract]); //eslint-disable-line

  return (
    <ProgressStepper>
      <CalculatorContainer />
    </ProgressStepper>
  );
};

export default Calculator;
