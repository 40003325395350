import {
  SET_STEP,
  SET_LOADING,
  UPDATE_CHECKBOX_ROW,
  UPDATE_DROPDOWN_ROW,
  SET_CONTRACT_DURATION,
  GET_INSURANCES,
  SET_INSURANCE,
  SET_VARIANTS,
  SET_VALUES,
  SET_ANNUAL_FEE,
  SET_DATE_VALUES,
  SET_DISPLAYED_VARIANT,
} from "../types";

import { FormState, Action } from "../../definitions";
import { Options } from "../../definitions";

const Reducer = (state: FormState, action: Action) => {
  switch (action.type) {
    case SET_STEP: {
      return {
        ...state,
        step: action.payLoad,
      };
    }
    case SET_CONTRACT_DURATION: {
      return {
        ...state,
        contractDuration: action.payLoad,
        run: state.run + 1,
      };
    }
    case UPDATE_CHECKBOX_ROW: {
      const newInsurances = state.insurances;

      if (!newInsurances) {
        return {
          ...state,
        };
      }

      //@todo check if this can be optimized
      for (const insurance of newInsurances) {
        const { variants } = insurance;
        for (const rate of variants) {
          const { conditions } = rate;
          for (const condition of conditions) {
            if (condition.name === action.payLoad) {
              condition.selected = !condition.selected;
            }
          }
        }
      }

      return {
        ...state,
        insurances: newInsurances,
        run: state.run + 1,
      };
    }

    case UPDATE_DROPDOWN_ROW: {
      let newInsurances = state.insurances;
      let currentBase = state.underlying;
      let currentDeath = state.deathBenefit;
      let currentProgression = state.progressionValue;

      if (!newInsurances) {
        return {
          ...state,
        };
      }

      //@todo check if this can be optimized
      for (const insurance of newInsurances) {
        const { variants } = insurance;
        for (const rate of variants) {
          const { conditions } = rate;
          for (const condition of conditions) {
            if (condition.name === action.payLoad.name) {
              condition.price = action.payLoad.value;
            }
          }
        }
      }

      /**
       * we additionally check for the values
       * to insert them individually in the state as we have some
       * logic depending on the relation between the chosen values
       */
      if (action.payLoad.name === Options.Todesfall) {
        currentDeath = action.payLoad.value;
      }

      if (action.payLoad.name === Options.Grundsumme) {
        currentBase = action.payLoad.value;
      }

      if (action.payLoad.name === Options.Progression) {
        currentProgression = action.payLoad.value;
      }

      // death cannot be larger thean base value
      if (currentDeath > currentBase) {
        currentDeath = currentBase;
      }

      return {
        ...state,
        insurances: newInsurances,
        run: state.run + 1,
        underlying: currentBase,
        deathBenefit: currentDeath,
        progressionValue: currentProgression,
      };
    }

    case GET_INSURANCES: {
      return {
        ...state,
        insurances: action.payLoad,
        loading: false,
      };
    }

    case SET_INSURANCE: {
      // get all names of current options and add to array
      let currentConditions: string[] = [];

      for (const insurance of action.payLoad) {
        const { variants } = insurance;
        for (const rate of variants) {
          const { conditions } = rate;
          for (const condition of conditions) {
            if (!currentConditions.includes(condition.name)) {
              currentConditions.push(condition.name);
            }
          }
        }
      }

      return {
        ...state,
        insurances: action.payLoad,
        currentConditions,
        loading: false,
      };
    }

    case SET_VALUES: {
      // build data object

      const newData = Object.keys(action.payLoad).reduce((accum, current) => {
        const newObj = {
          ...accum,
          [current]: action.payLoad[current],
        };
        return newObj;
      }, {});

      return {
        ...state,
        ...newData,
      };
    }

    case SET_DATE_VALUES: {
      return {
        ...state,
        [action.payLoad.name]: action.payLoad.value,
      };
    }

    case SET_ANNUAL_FEE: {
      return {
        ...state,
        annualFee: action.payLoad,
      };
    }

    /**
     * This pulls out the current variant names from the chosen contract type.
     * currentpy the contract type is determined by Name (string match) which
     * might be better to adapt by ID
     * @todo - match for _id instead of string name if possible
     */
    case SET_VARIANTS: {
      const variantNames: string[] = [];
      const variants: object[] = [];

      const { insuranceType, insurances } = action.payLoad;

      let insurance = insurances?.filter((insurance: any) => {
        return insurance?.contractType === insuranceType;
      });

      insurance?.[0]?.variants?.forEach((variant: any) => {
        variantNames.push(variant.option);
        variants.push(variant);
      });

      return {
        ...state,
        variantNames: variantNames,
        currentVariants: variants,
      };
    }

    case SET_DISPLAYED_VARIANT: {
      return {
        ...state,
        displayedVariantID: action.payLoad,
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payLoad,
      };
    }

    default: {
      return {
        ...state,
        loading: false,
      };
    }
  }
};

export default Reducer;
