// auth
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "SET_INSURANCES";
export const USER_LOGOUT = "SET_INSURANCES";

// admin
export const SET_INSURANCES = "SET_INSURANCES";
export const SET_CONTRACTS = "SET_CONTRACTS";
export const SET_VARIANTS = "SET_VARIANTS";
export const SET_LOADING = "SET_LOADING";
export const CREATE_INSURANCE = "CREATE_INSURANCE";
export const CREATE_CONTRACT = "CREATE_CONTRACT";
export const CREATE_VARIANT = "CREATE_VARIANT";
export const UPDATE_INSURANCE = "UPDATE_INSURANCE";
export const UPDATE_CONTRACT = "UPDATE_CONTRACT";
export const UPDATE_VARIANT = "UPDATE_VARIANT";
export const DELETE_INSURANCE = "DELETE_INSURANCE";
export const DELETE_CONTRACT = "DELETE_CONTRACT";
export const DELETE_VARIANT = "DELETE_VARIANT";
export const SET_CURRENT_INSURANCE = "SET_CURRENT_INSURANCE";
export const SET_CURRENT_CONTRACT = "SET_CURRENT_CONTRACT";
export const SET_CURRENT_VARIANT = "SET_CURRENT_VARIANT";

// form Data
export const SET_INSURANCE = "SET_INSURANCE";
export const SET_INSURANCE_TYPE = "SET_INSURANCE_TYPE";
export const SET_DISPLAYED_VARIANT = "SET_DISPLAYED_VARIANT";
export const SET_STEP = "SET_STEP";
export const SET_VALUES = "SET_VALUES";
export const SET_ANNUAL_FEE = "SET_ANNUAL_FEE";
export const SET_DATE_VALUES = "SET_DATE_VALUES";
export const UPDATE_CHECKBOX_ROW = "UPDATE_CHECKBOX_ROW";
export const UPDATE_DROPDOWN_ROW = "UPDATE_DROPDOWN_ROW";
export const SET_CONTRACT_DURATION = "SET_CONTRACT_DURATION";
export const CLEAR_FORM_STATE = "CLEAR_FORM_STATE";
// api calls
export const GET_INSURANCES = "GET_INSURANCES";

// App data
export const SET_THEME = "SET_THEME";
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
