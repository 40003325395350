import React, { useContext, useState, useEffect } from "react";
import FormContext from "../../context/form/formContext";
import {
  Radio,
  Paper,
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

const TabController = () => {
  const formContext = useContext(FormContext);
  const { currentVariants, setDisplayedVariantID, displayedVariantID } = formContext;

  const [value, setValue] = useState(displayedVariantID || "");
  const classes = useStyles();

  /**
   * @description inserts the new form Value into state
   * @param event
   * @param newValue
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (setDisplayedVariantID) setDisplayedVariantID(event.target.value);
  };

  /**
   * @description in case the variant ID is not set
   * on initial load, the initial value will be the first ID of the current Variant Array (index 0)
   */
  useEffect(() => {
    if (!displayedVariantID && currentVariants && currentVariants[0]) {
      setDisplayedVariantID?.(currentVariants[0]._id);
      setValue(currentVariants[0]._id);
    }
  }, [currentVariants, displayedVariantID, setDisplayedVariantID]);

  return (
    <Paper className={classes.container} square>
      <FormControl component="fieldset" style={{ margin: "1rem" }}>
        <FormLabel component="legend">Ihre Vertragsvariante</FormLabel>
        <RadioGroup
          row
          aria-label="Vertragsvariante"
          name="contract variant"
          value={value}
          onChange={(e) => handleChange(e)}
        >
          {currentVariants &&
            currentVariants.map((variant) => {
              return (
                <FormControlLabel
                  key={variant._id}
                  labelPlacement="bottom"
                  value={variant._id}
                  control={<Radio />}
                  label={variant.option}
                />
              );
            })}
        </RadioGroup>
      </FormControl>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    textAlign: "center",
  },
  tabHeader: {
    outline: "none",
  },
  tabs: {
    borderBottom: "1px solid #06131933",
  },
}));

export default TabController;
