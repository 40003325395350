import React, { useReducer } from "react";
import FormReducer from "./formReducer";
import FormContext from "./formContext";
import {
  SET_STEP,
  UPDATE_CHECKBOX_ROW,
  UPDATE_DROPDOWN_ROW,
  SET_CONTRACT_DURATION,
  GET_INSURANCES,
  SET_INSURANCE,
  SET_LOADING,
  SET_VARIANTS,
  CLEAR_FORM_STATE,
  SET_DISPLAYED_VARIANT,
  SET_DATE_VALUES,
  SET_VALUES,
  SET_ANNUAL_FEE,
} from "../types";
import axios from "axios";
import getAxiosJsonConfig from "../../utils/axiosJsonConfig";
import { dateFactory } from "../../utils/services/date-time";

const { REACT_APP_SERVER_URI } = process.env;

const FormState = (props: any) => {
  const initialState = {
    run: 0,
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    postcode: "",
    gender: "",
    dob: dateFactory(12, false),
    step: 0,
    contractDuration: 1,
    underlying: 0,
    deathBenefit: 0,
    progressionValue: 0,
    annualFee: 0,
    startDate: dateFactory(1, true),
    currentConditions: [""],
    variantNames: [""],
    currentVariants: [],
    displayedVariantID: "",
    insurances: null,
    loading: false,
  };

  const [state, dispatch]: [any, any] = useReducer<any>(FormReducer, initialState);

  const setStep = (step: number): void => {
    dispatch({ type: SET_STEP, payLoad: step });
    return;
  };

  const getContractTypes = async () => {
    // get all contract types
    try {
      let res = await fetch(`${REACT_APP_SERVER_URI}/contracts`);

      let data = await res.json();

      dispatch({ type: GET_INSURANCES, payLoad: data });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const updateCheckboxRow = (name: string): void => {
    // this updates the row of a selected option
    dispatch({ type: UPDATE_CHECKBOX_ROW, payLoad: name });
    return;
  };

  const updateDropdownRow = (selection: object): void => {
    // this updates the row of a selected option
    dispatch({ type: UPDATE_DROPDOWN_ROW, payLoad: selection });
    return;
  };

  const setContractDuration = (duration: 1 | 3): void => {
    dispatch({ type: SET_CONTRACT_DURATION, payLoad: duration });
    return;
  };

  const setAnnualFee = (fee: number): void => {
    dispatch({ type: SET_ANNUAL_FEE, payLoad: fee });
    return;
  };

  const setInsuranceData = async (contractID: string) => {
    try {
      let res = await fetch(`${REACT_APP_SERVER_URI}/calculator/insurances/${contractID}`);

      let insurance = await res.json();

      dispatch({ type: SET_INSURANCE, payLoad: insurance });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const setVariantNames = (insuranceType: string, insurances: any): void => {
    dispatch({ type: SET_VARIANTS, payLoad: { insuranceType, insurances } });
    return;
  };

  const setDisplayedVariantID = (variantID: string) => {
    dispatch({ type: SET_DISPLAYED_VARIANT, payLoad: variantID });
    return;
  };

  const clearFormState = (): void => {
    dispatch({ type: CLEAR_FORM_STATE, payLoad: null });
    return;
  };

  const setLoading = (isLoading: boolean) => {
    dispatch({ type: SET_LOADING, payLoad: isLoading });
    return;
  };

  const setDateValue = (data: any) => {
    dispatch({ type: SET_DATE_VALUES, payLoad: data });
    return;
  };

  /**
   * @description pass data to global state reducer
   * @param data the data used to populate the new state
   */
  const setValue = (data: any) => {
    dispatch({ type: SET_VALUES, payLoad: data });
    return;
  };

  const submitForm = async (extendState?: object, insurance = "") => {
    let path = "";

    switch (insurance) {
      case "instructor": {
        path = `${REACT_APP_SERVER_URI}/calculator/email/instructor`;
        break;
      }
      default: {
        path = `${REACT_APP_SERVER_URI}/calculator/email`;
        break;
      }
    }

    // submit the values to server
    try {
      const res = await axios.post(
        path,
        {
          ...state,
          ...extendState,
        },
        getAxiosJsonConfig()
      );
      return res;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  };

  const sendInitialEmail = async (email: string) => {
    // submit the values to server
    try {
      const res = await axios.post(
        `${REACT_APP_SERVER_URI}/calculator/email/initial`,
        { email },
        getAxiosJsonConfig()
      );
      return res;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  };

  return (
    <FormContext.Provider
      value={{
        run: state.run,
        step: state.step,
        firstName: state.firstName,
        lastName: state.lastName,
        address: state.address,
        email: state.email,
        city: state.city,
        postcode: state.postcode,
        gender: state.gender,
        dob: state.dob,
        startDate: state.startDate,
        insurances: state.insurances,
        contractDuration: state.contractDuration,
        underlying: state.underlying,
        annualFee: state.annualFee,
        deathBenefit: state.deathBenefit,
        progressionValue: state.progressionValue,
        currentConditions: state.currentConditions,
        variantNames: state.variantNames,
        currentVariants: state.currentVariants,
        displayedVariantID: state.displayedVariantID,
        loading: state.loading,
        setLoading,
        setStep,
        updateCheckboxRow,
        updateDropdownRow,
        setContractDuration,
        getContractTypes,
        setInsuranceData,
        setVariantNames,
        setValue,
        setAnnualFee,
        setDateValue,
        setDisplayedVariantID,
        clearFormState,
        submitForm,
        sendInitialEmail,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};

export default FormState;
