import React, { useContext, useEffect, useState } from "react";
import FormContext from "../../../../context/form/formContext";
import AppContext from "../../../../context/app/appContext";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import TableContent from "../elements/TableContent";
import { createSingleRow, createRateArray } from "../../../../utils";
import OptionPanel from "../../OptionPanel";
const RateData = () => {
  const history = useHistory();
  const [conditions, setConditions] = useState<Array<any>>([]);
  const classes = useStyles();

  const formContext = useContext(FormContext);
  const { insurances, currentConditions, setVariantNames, displayedVariantID } = formContext;

  const appContext = useContext(AppContext);
  const { insuranceType } = appContext;

  useEffect(() => {
    if (!insurances) history.push("/");

    // this extracts the names of the variants from insurances
    if (insuranceType && setVariantNames) {
      setVariantNames(insuranceType, insurances);
    }

    if (insurances && currentConditions && displayedVariantID) {
      setConditions([]);

      const rateArray = createRateArray(insurances, displayedVariantID);

      for (let condition of currentConditions) {
        setConditions((prev) => [...prev, [...createSingleRow(rateArray, condition)]]);
      }
    }
  }, [insurances, displayedVariantID]); //eslint-disable-line

  return (
    <div className={classes.flexContainer}>
      {insurances && insurances.length > 0 ? (
        <>
          <Grid
            className={classes.options}
            alignContent="stretch"
            container
            spacing={3}
            justifyContent="center"
            direction="column"
          >
            <OptionPanel />
          </Grid>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table aria-label="vergleichsrechner">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell colSpan={1} className={classes.tableHeadCell}>
                    Option
                  </TableCell>
                  <TableCell colSpan={1} className={classes.tableHeadCell}>
                    Wert
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableContent conditions={conditions} />
            </Table>
          </TableContainer>
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  tableHead: {
    background: theme.palette.secondary.main,
    color: "white",
  },
  tableHeadCell: {
    color: "white",
    width: "50%",
  },
  options: {
    margin: "0 2rem 0 0",
    marginBottom: "2rem",
    position: "sticky",
    top: "5rem",
    flex: "1",

    "@media screen and (max-width: 576px)": {
      position: "relative",
      width: "100%",
      top: 0,
      margin: 0,
    },
  },
  optionGridItem: {
    flex: "1",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",

    "@media screen and (max-width: 576px)": {
      flexDirection: "column",
    },
  },
  tableContainer: {
    flex: 3,
  },
}));

export default RateData;
