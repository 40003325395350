import { Grid, Paper } from "@material-ui/core";
import styled from "styled-components";

import React, { useState } from "react";
import { PersonalDataReduced } from "../../../calculator/content/steps/PersonalDataReduced";
import EnterAgreement from "../../../modals/EnterAgreement";

const Instructors = () => {
  const [open, setOpen] = useState(true);

  const handleOpen = (open: boolean) => {
    setOpen(open);
  };

  return (
    <div>
      <EnterAgreement handleOpen={handleOpen} open={open} />
      <h1>Haftpflichtversicherung für Fluglehrer & Einweiser</h1>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Container>
            <Paper style={{ padding: "1rem", background: "var(--color-primary--light)" }}>
              <FormTitle>Variante 1:</FormTitle>
              <FormSubTitle>Deckungssumme 1.000.000 EUR</FormSubTitle>
              für Personenschäden.
              <br />
              <br />
              Jahresbeitrag inkl. 19% Vers.St. = <strong>119,67 EUR</strong>
            </Paper>
          </Container>
        </Grid>
        <Grid item xs={6}>
          <Container>
            <Paper style={{ padding: "1rem", background: "var(--color-primary--light)" }}>
              <FormTitle>Variante 2:</FormTitle>
              <FormSubTitle>Deckungssumme 2.000.000 EUR</FormSubTitle>
              für Personenschäden.
              <br />
              <br />
              Jahresbeitrag inkl. 19% Vers.St. = <strong>184,97 EUR</strong>
            </Paper>
          </Container>
        </Grid>
        <Grid item xs={12}>
          <Container>
            <p style={{ color: "var(--color-primary)", fontSize: "1.4rem" }}>
              <strong> &rarr; Höhere Deckungssummen sind aktuell nicht möglich.</strong>
            </p>
          </Container>
        </Grid>
        <Grid item xs={12}>
          <Container>
            <h2>Unverbindliches Angebot via E-Mail zusenden:</h2>
            <br />
            <p>
              Wir senden Ihnen ein unverbindliches Angebot und die Versicherungsbedingungen via
              E-Mail zu.
            </p>
          </Container>
        </Grid>
        <Grid item xs={12}>
          <PersonalDataReduced />
        </Grid>
      </Grid>
    </div>
  );
};

const Container = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  height: 100%;
`;

const FormTitle = styled.h2`
  margin: 0 0 2rem 0;
  font-weight: 600;
  font-size: 1.75rem;
  text-transform: uppercase;
  position: relative;
  align-self: baseline;
`;

const FormSubTitle = styled.h2`
  margin: 1rem 0;
  font-weight: 600;
  font-size: 1.5rem;
  position: relative;
  align-self: baseline;
`;

export { Instructors };
