import React, { Fragment, useState, useEffect, useContext } from "react";
// Material UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Dialog, Button } from "@material-ui/core";
// Icons
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// Styles
import Title from "./Title";
import { makeStyles } from "@material-ui/core/styles";
// Context
import AdminContext from "../../context/admin/adminContext";
// Modal
import NewVariantModal from "./NewVariantModal";
import EditVariantModal from "./EditVariantModal";
// Utils
import confirmDelete from "../../utils/confirmDelete";

const Variants: React.FC = () => {
  const classes = useStyles();
  const adminContext = useContext(AdminContext);

  const {
    variants,
    getVariants,
    currentContract,
    deleteVariant,
    currentVariant,
    setCurrentVariant,
  } = adminContext;

  const [newVariantModal, setNewVariantModal] = useState(false);
  const [editVariantModal, setEditVariantModal] = useState(false);

  const editVariant = (_id: String) => {
    // set current variant
    if (setCurrentVariant) {
      setCurrentVariant(_id);
    }
    // open variant modal
    setEditVariantModal(true);
  };

  const removeVariant = (_id: String) => {
    const confirm = confirmDelete();

    if (confirm) {
      deleteVariant(_id);
    }
  };

  const handleClose = () => {
    setNewVariantModal(false);
    setEditVariantModal(false);
  };

  useEffect(() => {
    if (currentContract?._id !== undefined) {
      getVariants(currentContract._id);
    }
  }, [currentContract]); //eslint-disable-line

  return (
    <Fragment>
      <Title>Varianten</Title>
      <Table size="small">
        {currentContract?._id !== undefined ? (
          variants?.length === 0 ? (
            <h2>Keine Varianten gefunden für: {currentContract?.name}</h2>
          ) : (
            <Fragment>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>S.No.</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <br />
              </TableHead>
              <TableBody>
                {variants?.map((variant, index) => {
                  return (
                    <Fragment>
                      <TableRow key={index}>
                        <TableCell style={{ textAlign: "center" }}>{index + 1}</TableCell>
                        <TableCell className={classes.nameField}>{variant.option}</TableCell>
                        <TableCell>
                          {
                            <EditIcon
                              className={classes.icon}
                              onClick={() => editVariant(variant._id)}
                            />
                          }
                        </TableCell>
                        <TableCell>
                          <DeleteIcon onClick={() => removeVariant(variant._id)} />
                        </TableCell>
                      </TableRow>
                      <br />
                    </Fragment>
                  );
                })}
              </TableBody>
            </Fragment>
          )
        ) : (
          <h2 className={classes.noResourceMessage}>
            <>
              Bitte klicke auf einen Vertrag um seine Varianten anzuzeigen
              {currentContract}
            </>
          </h2>
        )}
      </Table>

      {currentContract?._id !== undefined && (
        <Fragment>
          <Button
            style={{ margin: "2rem" }}
            variant="contained"
            color="primary"
            onClick={() => setNewVariantModal(true)}
          >
            Neue Variante anlegen
          </Button>
          <Dialog open={newVariantModal} onClose={handleClose} aria-labelledby="Open Varaiant Form">
            <NewVariantModal handleClose={handleClose} />
          </Dialog>

          <Dialog
            open={editVariantModal}
            onClose={handleClose}
            aria-labelledby="Edit Varaiant Form"
          >
            <EditVariantModal currentVariant={currentVariant} handleClose={handleClose} />
          </Dialog>
        </Fragment>
      )}
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  noResourceMessage: {
    textAlign: "center",
    margin: "4rem 0",
  },
  nameField: {
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#f2f2f2",
      cursor: "pointer",
    },
  },
  icon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default Variants;
