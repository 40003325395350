import React, { useContext } from "react";
import {
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Paper,
} from "@material-ui/core";
import FormContext from "../../context/form/formContext";
import { makeStyles, Theme } from "@material-ui/core/styles";

const DurationToggle = () => {
  const formContext = useContext(FormContext);

  const { contractDuration, setContractDuration } = formContext;

  const classes = useStyles();

  const handleChange = (target: HTMLInputElement) => {
    if (setContractDuration) {
      setContractDuration(parseInt(target.value));
    }
  };

  return (
    <Paper className={classes.container} square>
      <FormControl component="fieldset" style={{ margin: "1rem" }}>
        <FormLabel component="legend">Ihre Vertragslaufzeit</FormLabel>
        <RadioGroup
          row
          aria-label="Vertragslaufzeit"
          name="contractDuration"
          value={contractDuration && contractDuration}
          onChange={(e) => handleChange(e.target as HTMLInputElement)}
        >
          <FormControlLabel labelPlacement="bottom" value={1} control={<Radio />} label="1 Jahr" />
          <FormControlLabel labelPlacement="bottom" value={3} control={<Radio />} label="3 Jahre" />
        </RadioGroup>
      </FormControl>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default DurationToggle;
