import {
  SET_THEME,
  SET_INSURANCE,
  SET_CURRENT_CONTRACT,
  SET_LOADING,
  TOGGLE_DRAWER,
} from "../types";

import { AppContextState, Action } from "../../definitions";

const Reducer = (state: AppContextState, action: Action) => {
  switch (action.type) {
    case SET_THEME: {
      return {
        ...state,
      };
    }
    case SET_INSURANCE: {
      return {
        ...state,
        insurance: action.payLoad,
        insuranceType: action.payLoad,
      };
    }

    case SET_CURRENT_CONTRACT: {
      return {
        ...state,
        currentContract: action.payLoad,
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payLoad,
      };
    }

    case TOGGLE_DRAWER: {
      return {
        ...state,
        drawerOpen: !state.drawerOpen,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default Reducer;
