import React, { useReducer, useContext } from "react";
import AdminReducer from "./adminReducer";
import AdminContext from "./adminContext";
// Types
import {
  SET_INSURANCES,
  SET_CONTRACTS,
  SET_VARIANTS,
  SET_CURRENT_INSURANCE,
  SET_CURRENT_CONTRACT,
  SET_CURRENT_VARIANT,
  CREATE_INSURANCE,
  CREATE_CONTRACT,
  CREATE_VARIANT,
  UPDATE_INSURANCE,
  UPDATE_CONTRACT,
  UPDATE_VARIANT,
  DELETE_CONTRACT,
  DELETE_INSURANCE,
  DELETE_VARIANT,
} from "../types";
// Axios
import axios from "axios";
import axiosConfig from "../../utils/axiosConfig";
import { useHistory } from "react-router-dom";
import { Contract, Insurance } from "../../definitions";
import createFormBody from "../../utils/createFormBody";
import AuthContext from "../auth/authContext";
import getAxiosJsonConfig from "../../utils/axiosJsonConfig";
const { REACT_APP_SERVER_URI } = process.env;
const AdminState = (props: any) => {
  const initialState = {
    currentInsurance: "",
    currentContract: "",
    currentVariant: "",
    insurances: [],
    contracts: [],
    variants: [],
  };

  const history = useHistory();
  const [state, dispatch]: [any, any] = useReducer<any>(AdminReducer, initialState);

  const authContext = useContext(AuthContext);
  const { logout } = authContext;

  const getInsurances = async (): Promise<void> => {
    try {
      const res = await axios.get(`${REACT_APP_SERVER_URI}/insurances`, axiosConfig());
      dispatch({ type: SET_INSURANCES, payLoad: res.data });
    } catch (error: any) {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        logout && logout();
        history.push("/");
      }
      return;
    }
  };

  const getContracts = async (insuranceId: string): Promise<void> => {
    try {
      const res = await axios.get(
        `${REACT_APP_SERVER_URI}/contracts/${insuranceId}`,
        axiosConfig()
      );
      dispatch({ type: SET_CONTRACTS, payLoad: res.data });
    } catch (error: any) {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        logout && logout();
        history.push("/");
      }
      return;
    }
  };

  const getVariants = async (contractId: string): Promise<void> => {
    try {
      const res = await axios.get(`${REACT_APP_SERVER_URI}/variants/${contractId}`, axiosConfig());
      dispatch({ type: SET_VARIANTS, payLoad: res.data });
      return;
    } catch (error: any) {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        logout && logout();
        history.push("/");
      }
      return;
    }
  };

  const getCurrentInsurance = async (insuranceId: string): Promise<void> => {
    try {
      let current = state.insurances.filter(
        (insurance: Insurance) => insurance?._id === insuranceId
      );

      current = current[0];
      dispatch({ type: SET_CURRENT_INSURANCE, payLoad: current });
    } catch (error: any) {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        logout && logout();
        history.push("/");
      }
      return;
    }
  };

  const getCurrentContract = async (contractId: string): Promise<void> => {
    try {
      let current = state.contracts.filter((contract: Contract) => contract?._id === contractId);

      current = current[0];
      dispatch({ type: SET_CURRENT_CONTRACT, payLoad: current });
    } catch (error: any) {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        logout && logout();
        history.push("/");
      }
      return;
    }
  };

  const createInsurance = async (body: any): Promise<void> => {
    try {
      let formBody = createFormBody(body);

      const res = await axios.post(`${REACT_APP_SERVER_URI}/insurances`, formBody, axiosConfig());

      dispatch({ type: CREATE_INSURANCE, payLoad: res.data });
    } catch (error: any) {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        logout && logout();
        history.push("/");
      }
      return;
    }
  };

  const createContract = async (body: any): Promise<void> => {
    try {
      let formBody = createFormBody(body);

      const res = await axios.post(`${REACT_APP_SERVER_URI}/contracts`, formBody, axiosConfig());

      dispatch({ type: CREATE_CONTRACT, payLoad: res.data });
    } catch (error: any) {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        logout && logout();
        history.push("/");
      }
      return;
    }
  };

  const createVariant = async (body: any): Promise<void> => {
    try {
      let formBody = body;
      const res = await axios.post(
        `${REACT_APP_SERVER_URI}/variants`,
        formBody,
        getAxiosJsonConfig()
      );

      dispatch({ type: CREATE_VARIANT, payLoad: res.data });
    } catch (error: any) {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        logout && logout();
        history.push("/");
      }
      return;
    }
  };

  const setCurrentVariant = (_id: string) => {
    dispatch({ type: SET_CURRENT_VARIANT, payLoad: _id });
  };

  const updateInsurance = async (_id: string, body: any): Promise<void> => {
    try {
      let formBody = createFormBody(body);

      const res = await axios.put(
        `${REACT_APP_SERVER_URI}/insurances/${_id}`,
        formBody,
        axiosConfig()
      );

      dispatch({ type: UPDATE_INSURANCE, payLoad: res.data });
    } catch (error: any) {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        logout && logout();
        history.push("/");
      }
      return;
    }
  };

  const updateContract = async (_id: string, body: any): Promise<void> => {
    try {
      let formBody = createFormBody(body);

      const res = await axios.put(
        `${REACT_APP_SERVER_URI}/contracts/${_id}`,
        formBody,
        axiosConfig()
      );

      dispatch({ type: UPDATE_CONTRACT, payLoad: res.data });
    } catch (error: any) {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        logout && logout();
        history.push("/");
      }
      return;
    }
  };

  const updateVariant = async (_id: string, body: any): Promise<void> => {
    try {
      const formBody = body;

      const res = await axios.patch(
        `${REACT_APP_SERVER_URI}/variants/${_id}`,
        formBody,
        getAxiosJsonConfig()
      );

      dispatch({ type: UPDATE_VARIANT, payLoad: res.data });
    } catch (error: any) {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        logout && logout();
        history.push("/");
      }
      return;
    }
  };

  const deleteInsurance = async (insuranceId: string): Promise<void> => {
    try {
      const res = await axios.delete(
        `${REACT_APP_SERVER_URI}/insurances/${insuranceId}`,
        axiosConfig()
      );

      dispatch({ type: DELETE_INSURANCE, payLoad: res.data });
    } catch (error: any) {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        logout && logout();
        history.push("/");
      }
      return;
    }
  };

  const deleteContract = async (contractId: string): Promise<void> => {
    try {
      const res = await axios.delete(
        `${REACT_APP_SERVER_URI}/contracts/${contractId}`,
        axiosConfig()
      );

      dispatch({ type: DELETE_CONTRACT, payLoad: res.data });
    } catch (error: any) {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        logout && logout();
        history.push("/");
      }
      return;
    }
  };

  const deleteVariant = async (variantId: string): Promise<void> => {
    try {
      const res = await axios.delete(
        `${REACT_APP_SERVER_URI}/variants/${variantId}`,
        axiosConfig()
      );

      dispatch({ type: DELETE_VARIANT, payLoad: res.data });
    } catch (error: any) {
      alert(error.response.data.message);
      if (error.response.status === 401) {
        logout && logout();
        history.push("/");
      }
      return;
    }
  };

  return (
    <AdminContext.Provider
      value={{
        insurances: state.insurances,
        contracts: state.contracts,
        variants: state.variants,
        currentInsurance: state.currentInsurance,
        currentContract: state.currentContract,
        currentVariant: state.currentVariant,
        getInsurances,
        getContracts,
        getVariants,
        getCurrentInsurance,
        getCurrentContract,
        createInsurance,
        createContract,
        createVariant,
        updateInsurance,
        updateContract,
        updateVariant,
        setCurrentVariant,
        deleteInsurance,
        deleteContract,
        deleteVariant,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminState;
