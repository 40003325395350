import React from "react";
import TabController from "./TabController";
import DurationToggle from "../calculator/DurationToggle";
import { Grid } from "@material-ui/core";
import PriceDisplayPanel from "./PriceDisplayPanel";
import ConditionPanel from "./ConditionPanel";

const OptionPanel = () => {
  return (
    <>
      <Grid item>
        <PriceDisplayPanel />
      </Grid>
      <Grid item>
        <TabController />
      </Grid>
      <Grid item>
        <DurationToggle />
      </Grid>
      <Grid item>
        <ConditionPanel />
      </Grid>
    </>
  );
};

export default OptionPanel;
