import React, { useContext, useEffect, useState } from "react";
import FormContext from "../../../../context/form/formContext";
import { TableCell, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// type for this component

const MaxInsuranceValue = () => {
  const formContext = useContext(FormContext);
  const { underlying, progressionValue } = formContext;

  const [maxVal, setMaxVal] = useState(0);
  const classes = useStyles();
  /**
   * @description This effect calculates the maximum value that is paid out in case
   * of death. The value is displayed in the table cell underneath the
   * basevalue and progression
   */
  useEffect(() => {
    if (typeof progressionValue !== "undefined" && underlying) {
      let calculatedMaxValue = (progressionValue / 100) * underlying;

      setMaxVal(Math.max(calculatedMaxValue, underlying));
    }
    return;
  }, [underlying, progressionValue]);

  return (
    <TableRow>
      <TableCell className={classes.highlighted}>Leistung bei Voll-Invalidität</TableCell>
      <TableCell className={classes.highlighted}>
        {maxVal === 0 ? "0€ bei aktueller Konfiguration" : `${maxVal.toLocaleString("de-DE")}€`}
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme) => ({
  highlighted: {
    fontWeight: 600,
    color: theme.palette.secondary.main,
  },
}));

export default MaxInsuranceValue;
