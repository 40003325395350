/**
 * list of progression values that are based on the current insurance
 */

export const progressionListBase = new Map();

progressionListBase.set(0, 0.3);
progressionListBase.set(225, 0.39);
progressionListBase.set(350, 0.48);
progressionListBase.set(500, 0.54);

export const progressionListTop = new Map();

progressionListTop.set(0, 0.97);
progressionListTop.set(225, 1.25);
progressionListTop.set(350, 1.47);
progressionListTop.set(500, 1.71);
