import { LOGIN_SUCCESS, LOGIN_FAILED, USER_LOGOUT } from "../types";

import { AuthState, Action } from "../../definitions";

const Reducer = (state: AuthState, action: Action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      localStorage.setItem("userId", action.payLoad.user._id);
      localStorage.setItem("email", action.payLoad.user.email);
      localStorage.setItem("token", action.payLoad.token);
      localStorage.setItem("isAuthenticated", "true");

      return {
        ...state,
        userId: action.payLoad.user._id,
        email: action.payLoad.user.email,
        token: action.payLoad.token,
        isAuthenticated: true,
      };
    }
    case LOGIN_FAILED: {
      localStorage.clear();
      return {
        ...state,
        userId: "",
        email: "",
        token: "",
        isAuthenticated: false,
      };
    }
    case USER_LOGOUT: {
      localStorage.clear();
      return {
        ...state,
        userId: "",
        email: "",
        token: "",
        isAuthenticated: false,
      };
    }

    // fallback, change nothing
    default: {
      return {
        ...state,
      };
    }
  }
};

export default Reducer;
