// @description: stepper component to indicate current step in process
// @todo: refactoring to global context state

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Stepper, Step, StepLabel, StepConnector, Button, Typography } from "@material-ui/core";

import FormContext from "../../context/form/formContext";
import AppContext from "../../context/app/appContext";

import { validateInsuranceForm } from "../../utils/services/service-validator";
import { useSnackBars } from "../../utils/hooks/snackbar/useSnackbar";

function getSteps(): Array<string> {
  return ["Versicherung vergleichen", "Daten eingeben", "Angebot anfordern"];
}

export default function ProgressStepper(props: any) {
  const formContext = useContext(FormContext);
  const { step, setStep, submitForm, loading, setLoading, sendInitialEmail, email } = formContext;

  const appContext = useContext(AppContext);
  const { currentContract } = appContext;

  //@ts-ignore
  const { addAlert } = useSnackBars();
  const classes = useStyles();
  const history = useHistory();
  const steps = getSteps();

  const handleNext = () => {
    if (step !== undefined && setStep !== undefined) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step !== undefined && setStep !== undefined) {
      setStep(step - 1);
    }
  };

  const handleReset = () => {
    if (step !== undefined && setStep !== undefined) {
      setStep(0);
    }
  };

  const handleSubmit = async () => {
    try {
      const formValidation = validateInsuranceForm(formContext);

      if (formValidation.isError) {
        throw new Error(formValidation.message || "Ihre Formulareingabe ist ungültig");
      }

      // set loading
      setLoading?.(true);

      // first contact information
      if (email) {
        await sendInitialEmail?.(email);
      }

      await submitForm?.({ currentContract });

      history.push("/done");
    } catch (error: any) {
      addAlert({ type: "error", message: error.message });
    } finally {
      setLoading?.(false);
    }
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={step !== undefined ? step : 0}
        connector={<ColorlibConnector />}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {props.children}
      <div>
        {step === steps.length ? (
          <div>
            <Typography className={classes.instructions}>Alle Schritte beendet</Typography>
            <Button onClick={() => handleReset()}>Zurücksetzen</Button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>
              <Button
                disabled={step === 0}
                onClick={() => handleBack()}
                className={classes.backButton}
                variant="contained"
                color="secondary"
              >
                Zurück
              </Button>
              {step !== steps.length - 1 ? (
                <Button variant="contained" color="primary" onClick={() => handleNext()}>
                  Weiter
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={() => handleSubmit()}
                >
                  unverbindliches <br /> Angebot anfordern
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: "1.2rem",
  },
}));

const ColorlibConnector = withStyles({
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,var(--color-primary) 0%,rgb(233,64,87) 50%,var(--color-secondary) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,var(--color-primary) 0%,rgb(233,64,87) 50%,var(--color-secondary) 100%",
    },
  },
  line: {
    height: 4,
    border: 1,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);
