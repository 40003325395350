const axiosConfig = () => ({
  withCredentials: true,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Credentials": "true",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    Accept: "application/json",
  },
  validateStatus: (status: Number) => {
    if (status >= 200 && status < 300) {
      return true;
    } else {
      if (status === 401) {
        window.localStorage.clear();
      }
      return false;
    }
  },
});

export default axiosConfig;
