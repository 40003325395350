import React, { useContext, useState, useEffect } from "react";

//Context
import AdminContext from "../../context/admin/adminContext";

//Diaglogs
import { DialogActions, DialogContent, DialogTitle, Button, TextField } from "@material-ui/core";

interface Props {
  handleClose: any;
}

const EditContractModal: React.FC<Props> = ({ handleClose }) => {
  const adminContext = useContext(AdminContext);
  const { updateContract, currentContract } = adminContext;

  const [formData, setFormData] = useState({
    name: "",
    insurance: "",
  });

  const handleChange = (e: any) => {
    if (e.target) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    updateContract(currentContract?._id, formData);
    handleClose();
  };

  useEffect(() => {
    if (currentContract) {
      setFormData((prev) => {
        return {
          ...prev,
          name: currentContract?.name,
          insurance: currentContract?.insurance,
        };
      });
    }
  }, [currentContract]);

  return (
    <form onSubmit={(e) => onSubmit(e)} method="POST">
      <DialogTitle id="form-dialog-title">Edit Contract</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          required
          name="name"
          value={formData.name}
          label="Contract name"
          type="text"
          fullWidth
          onChange={(e) => handleChange(e)}
        />
        <TextField
          margin="dense"
          required
          name="insurance"
          value={formData.insurance}
          label="Insurance"
          type="text"
          fullWidth
          disabled={true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Abbrechen
        </Button>
        <Button color="primary" type="submit">
          Aktualisieren
        </Button>
      </DialogActions>
    </form>
  );
};

export default EditContractModal;
