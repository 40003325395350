import React, { useReducer } from "react";
import AuthReducer from "./authReducer";
import AuthContext from "./authContext";
// Types
import { LOGIN_SUCCESS, LOGIN_FAILED, USER_LOGOUT } from "../types";
// Axios
import axios from "axios";
import axiosConfig from "../../utils/axiosConfig";
import createFormBody from "../../utils/createFormBody";
const { REACT_APP_SERVER_URI } = process.env;

const AdminState = (props: any) => {
  const initialState = {
    userId: "",
    email: "",
    token: localStorage.getItem("token") || null,
    isAuthenticated: localStorage.getItem("isAuthenticated") === "true" ? true : false,
  };

  const [state, dispatch]: [any, any] = useReducer<any>(AuthReducer, initialState);

  const login = async (data: any): Promise<void> => {
    try {
      const formBody = createFormBody(data);
      const res = await axios.post(`${REACT_APP_SERVER_URI}/auth/login`, formBody, axiosConfig());
      dispatch({ type: LOGIN_SUCCESS, payLoad: res.data });
    } catch (error: any) {
      dispatch({ type: LOGIN_FAILED });
      throw error;
    }
  };

  const logout = async (): Promise<void> => {
    dispatch({ type: USER_LOGOUT });
  };

  return (
    <AuthContext.Provider
      value={{
        userId: state.userId,
        email: state.email,
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        login,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AdminState;
