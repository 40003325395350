import React, { useContext } from "react";
import { TableCell, Checkbox } from "@material-ui/core";
import { Condition } from "../../../../definitions";
import FormContext from "../../../../context/form/formContext";

const CheckBoxCell = (cell: Condition) => {
  const formContext = useContext(FormContext);
  const { updateCheckboxRow } = formContext;
  const { name, selected } = cell;

  const handleChange = (e: React.ChangeEvent) => {
    if (cell.type !== "checkbox") return;

    if (typeof updateCheckboxRow !== "undefined") {
      updateCheckboxRow(name);
    }
  };

  return (
    <TableCell>
      <Checkbox checked={selected} onChange={(e) => handleChange(e)}></Checkbox>
    </TableCell>
  );
};

export default CheckBoxCell;
