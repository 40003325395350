import React, { useReducer } from "react";
import AppReducer from "./appReducer";
import AppContext from "./appContext";
import { SET_INSURANCE, SET_CURRENT_CONTRACT, SET_LOADING, TOGGLE_DRAWER } from "../types";
const AppState = (props: any) => {
  const initialState = {
    insurance: "",
    insuranceType: "",
    currentContract: [],
    loading: false,
    drawerOpen: false,
  };

  const [state, dispatch]: [any, any] = useReducer<any>(AppReducer, initialState);

  const setInsurance = (insurance: string) => {
    dispatch({ type: SET_INSURANCE, payLoad: insurance });
  };

  const setCurrentContract = (key: string) => {
    dispatch({ type: SET_CURRENT_CONTRACT, payLoad: key });
  };

  const setLoading = (isLoading: boolean) => {
    dispatch({ type: SET_LOADING, action: isLoading });
  };

  const toggleDrawer = () => {
    dispatch({ type: TOGGLE_DRAWER, action: null });
  };

  return (
    <AppContext.Provider
      value={{
        insurance: state.insurance,
        insuranceType: state.insuranceType,
        currentContract: state.currentContract,
        loading: state.loading,
        drawerOpen: state.drawerOpen,
        setInsurance,
        setCurrentContract,
        setLoading,
        toggleDrawer,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

AppState.propTypes = {};

export default AppState;
