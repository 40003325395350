import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TextField, InputAdornment, Paper } from "@material-ui/core";

import AccountCircle from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";

import FormContext from "../../../../context/form/formContext";
import DateInput from "../elements/DateInput";
import { ValidStateType } from "../../../../definitions/interfaces";
import { dateFactory } from "../../../../utils/services/date-time";

const PersonalData = () => {
  const classes = useStyles();

  const formContext = useContext(FormContext);

  const { setValue, email, firstName, lastName, address, city, postcode, dob, startDate } =
    formContext;

  const [formState, setFormState] = useState({
    firstName,
    lastName,
    email,
    address,
    postcode,
    city,
    dob,
    startDate,
  });

  const [
    { firstNameValid, lastNameValid, emailValid, addressValid, postcodeValid, cityValid },
    setValidateState,
  ] = useState<ValidStateType>({
    firstNameValid: null,
    lastNameValid: null,
    emailValid: null,
    addressValid: null,
    postcodeValid: null,
    cityValid: null,
  });

  useEffect(() => {
    setValue?.(formState);
  }, [formState]); // eslint-disable-line

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target?.name) {
      const { name, value } = e.target;
      setFormState((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  const validateField = (fieldName: string, value: string) => {
    if (!value)
      setValidateState((prev) => {
        return {
          ...prev,
          [fieldName]: false,
        };
      });

    switch (fieldName) {
      case "address": {
        const isValid = value !== "";

        setValidateState((prev) => {
          return {
            ...prev,
            addressValid: isValid,
          };
        });

        break;
      }
      case "firstName": {
        setValidateState((prev) => {
          return {
            ...prev,
            firstNameValid: value.length > 0,
          };
        });
        break;
      }

      case "lastName": {
        setValidateState((prev) => {
          return {
            ...prev,
            lastNameValid: value.length > 0,
          };
        });
        break;
      }

      case "city": {
        setValidateState((prev) => {
          return {
            ...prev,
            cityValid: value.length > 0,
          };
        });
        break;
      }

      default: {
        return;
      }
    }
  };

  return (
    <FormContainer>
      <Paper className={classes.paper}>
        <FormTitle>Pers&ouml;nliche Daten</FormTitle>
        <FormRow>
          <TextField
            error={typeof firstNameValid !== "boolean" ? false : !firstNameValid}
            onChange={(e) => handleChange(e)}
            className={classes.input}
            label="Vorname"
            value={firstName}
            name="firstName"
            onBlur={(e) => validateField(e.target.name, e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            error={typeof lastNameValid !== "boolean" ? false : !lastNameValid}
            onChange={(e) => handleChange(e)}
            className={classes.input}
            label="Nachname"
            value={lastName}
            name="lastName"
            onBlur={(e) => validateField(e.target.name, e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
        </FormRow>

        <FormRow>
          <TextField
            error={typeof addressValid !== "boolean" ? false : !addressValid}
            fullWidth
            onChange={(e) => handleChange(e)}
            className={classes.input}
            label="Strasse + Hausnummer"
            value={address}
            name="address"
            onBlur={(e) => validateField(e.target.name, e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormRow>
        <FormRow>
          <TextField
            error={typeof postcodeValid !== "boolean" ? false : !postcodeValid}
            fullWidth
            onChange={(e) => handleChange(e)}
            className={classes.input}
            label="PLZ"
            value={postcode}
            type="number"
            name="postcode"
            onBlur={(e) => validateField(e.target.name, e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            error={typeof cityValid !== "boolean" ? false : !cityValid}
            fullWidth
            onChange={(e) => handleChange(e)}
            className={classes.input}
            label="Ort"
            value={city}
            name="city"
            onBlur={(e) => validateField(e.target.name, e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormRow>
        <FormRow>
          <TextField
            error={typeof emailValid !== "boolean" ? false : !emailValid}
            helperText={typeof emailValid === "boolean" ? "Bitte eine gültige Email eingeben" : ""}
            onChange={(e) => handleChange(e)}
            fullWidth
            className={classes.input}
            label="Email"
            type="email"
            name="email"
            onBlur={(e) => validateField(e.target.name, e.target.value)}
            value={email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormRow>
        <FormRow>
          <DateInput
            name="dob"
            label="Geburtsdatum"
            initialDate={dateFactory(12, false)}
            isFullWidth={true}
          />
        </FormRow>
        <FormRow>
          <DateInput
            name="startDate"
            label="Versicherungsbeginn"
            helperText="An welchem Datum soll die Versicherung in Kraft treten?"
            initialDate={dateFactory(1, true)}
            isFullWidth={true}
          />
        </FormRow>
      </Paper>
    </FormContainer>
  );
};

const FormContainer = styled.form`
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 55rem;
  margin: auto;
`;

const FormRow = styled.div`
  display: flex;
  margin: 0.5rem 0;
`;

const FormTitle = styled.h2`
  margin: 2rem 0;
  font-weight: 600;
  font-size: 1.75rem;
  text-transform: uppercase;
  position: relative;
  align-self: baseline;
  &::after {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 1px;
    background: var(--color-secondary-15);
    width: 100%;
    height: 4px;
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      padding: "1rem",
    },
    paper: {
      padding: "1rem",
    },
  })
);
export default PersonalData;
