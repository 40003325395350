import styled from "styled-components";

const MainTitle = styled.h1`
  text-align: center;
  font-size: calc(1vw + 1.25rem);
  font-weight: 300;
  margin: 5rem 0;
`;

export { MainTitle };
