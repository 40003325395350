import React, { Fragment, useContext, useEffect } from "react";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AppContext from "../../../../context/app/appContext";
import { CellTypes, Condition } from "../../../../definitions";
import { isCondition } from "../../../../utils";
import CheckBoxCell from "./CheckBoxCell";
import DescriptionCell from "./DescriptionCell";
import DropdownCell from "./DropdownCell";
import FixedCell from "./FixedCell";
import MaxInsuranceValue from "./MaxInsuranceValue";
import PriceCell from "./PriceCell";

const TableContent = ({ conditions }: any) => {
  const appContext = useContext(AppContext);
  const { insuranceType } = appContext;
  const history = useHistory();

  useEffect(() => {
    if (!insuranceType) {
      history.push("/");
    }
    return;
  }, [insuranceType, conditions, history]);

  return (
    <TableBody>
      {conditions.map((row: Array<string | Condition>, index: number) => (
        <Fragment key={index}>
          <TableRow key={index}>
            {row.map((cell: string | Condition) => {
              if (typeof cell === "string") {
                return <TableCell key={cell}>{cell}</TableCell>;
              }

              if (isCondition(cell)) {
                switch (cell.type) {
                  case CellTypes.Checkbox: {
                    return <CheckBoxCell key={cell._id} {...cell} />;
                  }
                  case CellTypes.Dropdown: {
                    return <DropdownCell key={cell._id} {...cell} />;
                  }
                  case CellTypes.Fix: {
                    return <FixedCell key={cell._id} {...cell} />;
                  }
                  case CellTypes.Information: {
                    return <DescriptionCell key={cell._id} {...cell} />;
                  }
                  case CellTypes.Price: {
                    return <PriceCell key={cell._id} {...cell} />;
                  }
                  default:
                    return null;
                }
              }
              return null;
            })}
          </TableRow>

          {row[0] === "Leistung im Todesfall" && <MaxInsuranceValue />}
        </Fragment>
      ))}
    </TableBody>
  );
};

export default TableContent;
