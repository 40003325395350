import React, { Fragment, useContext, useState, useEffect } from "react";
import { Condition, Options, CellTypes } from "../../definitions";
import styled from "styled-components";

//Context
import AdminContext from "../../context/admin/adminContext";

//Diaglogs
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

interface Props {
  handleClose: any;
}

const NewContractModal: React.FC<Props> = ({ handleClose }) => {
  const adminContext = useContext(AdminContext);
  const { currentContract, createVariant, currentVariant } = adminContext;

  const [optionObject, setOptionObject] = useState({
    _id: currentContract,
    name: "",
    description: "",
    price: 0,
    type: "",
    selected: false,
    included: false,
    factor: 0.0,
    options: [],
    optionString: "",
  });

  let { name, description, price, type, selected, included, factor, optionString } = optionObject;

  const [formData, setFormData] = useState<any>({
    option: "",
    contract: currentContract?._id,
    contractName: currentContract?.name,
    imageUrl: "",
    conditions: [
      {
        name: "",
        description: "",
        price: 0,
        type: "",
        selected: false,
        included: false,
        factor: 0.0,
        options: [0],
      },
    ],
  });

  useEffect(() => {
    //prefill
    if (currentVariant) {
      let { conditions } = currentVariant;
      setFormData((prev: any) => {
        return {
          ...prev,
          conditions,
          name,
        };
      });
    }
  }, [currentVariant]); //eslint-disable-line

  const addCondition = () => {
    let oldConditions = formData.conditions;

    /**
     * this checks if the option name is allowed
     */
    const possibleValues: string[] = Object.values(Options);

    if (!possibleValues.includes(optionObject.name)) {
      alert("please choose an correct condition name");
      return;
    }

    if (oldConditions[0].name === "") {
      oldConditions.splice(0, 1);
    }

    oldConditions.push({
      name,
      description,
      price,
      type,
      selected,
      included,
      factor,
      options: optionString.split(",").map(Number),
    });

    setOptionObject({
      ...optionObject,
      name: "",
      description: "",
      price: 0,
      type: "",
      selected: false,
      included: false,
      factor: 0.0,
      options: [],
      optionString: "",
    });
  };

  const handleChange = (e: any) => {
    if (e.target) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleObjectChange = (e: any) => {
    if (e.target) {
      if (e.target.name === "selected" || e.target.name === "included") {
        setOptionObject({ ...optionObject, [e.target.name]: e.target.checked });
        return;
      }
      setOptionObject({ ...optionObject, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    createVariant(formData);
    handleClose();
  };

  return (
    <Fragment>
      <form onSubmit={(e) => onSubmit(e)} method="POST">
        <DialogTitle id="form-dialog-title">Neue Variante</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            required
            name="option"
            label="name der Variante/Option"
            value={formData.option}
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
          />
          <TextField
            margin="dense"
            required
            name="contract"
            label="Vertragstyp"
            type="text"
            fullWidth
            value={formData.contractName}
            disabled={true}
            onChange={(e) => handleChange(e)}
          />

          <TextField
            margin="dense"
            name="imageUrl"
            label="Logo URL"
            type="text"
            value={formData.imageUrl}
            fullWidth
            onChange={(e) => handleChange(e)}
          />

          <TextField
            margin="dense"
            required={formData.conditions.length < 1}
            name="name"
            label="Name der Kondition"
            type="text"
            value={name}
            select
            fullWidth
            onChange={(e) => handleObjectChange(e)}
          >
            {Object.values(Options).map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            required={formData.conditions.length < 1}
            name="description"
            label="Beschreibung der Kondition"
            type="text"
            value={description}
            fullWidth
            onChange={(e) => handleObjectChange(e)}
          />
          <TextField
            margin="dense"
            required={formData.conditions.length < 1}
            name="price"
            label="Preis Kondition (falls vorhanden)"
            type="number"
            value={price}
            fullWidth
            onChange={(e) => handleObjectChange(e)}
          />
          <FormControl required={formData.conditions.length < 1}>
            <InputLabel>Condition Type</InputLabel>
            <Select
              name="type"
              value={type}
              style={{ width: "20rem" }}
              onChange={(e) => handleObjectChange(e)}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value={CellTypes.Fix}>Fix</MenuItem>
              <MenuItem value={CellTypes.Checkbox}>Checkbox</MenuItem>
              <MenuItem value={CellTypes.Dropdown}>Dropdown</MenuItem>
              <MenuItem value={CellTypes.Information}>information</MenuItem>
            </Select>
          </FormControl>
          <br />
          {type === "fix" && (
            <FormControlLabel
              control={
                <Checkbox
                  name="included"
                  value={included}
                  checked={included}
                  onChange={(e) => handleObjectChange(e)}
                />
              }
              label="Ist inbegriffen *"
            />
          )}
          {type === "checkbox" && (
            <FormControlLabel
              control={
                <Checkbox
                  name="selected"
                  value={selected}
                  checked={selected}
                  onChange={(e) => handleObjectChange(e)}
                />
              }
              label="inbegriffen *"
            />
          )}

          {type === "dropdown" && (
            <Fragment>
              <TextField
                margin="dense"
                required
                name="factor"
                label="Condition Factor *"
                type="number"
                value={factor}
                fullWidth
                onChange={(e) => handleObjectChange(e)}
              />
              <TextField
                margin="dense"
                required
                name="optionString"
                label="Enter option Values (comma separated) *"
                type="text"
                value={optionString}
                fullWidth
                onChange={(e) => handleObjectChange(e)}
              />
            </Fragment>
          )}

          {type !== "" && (
            <Fragment>
              <br />
              <Button
                variant="outlined"
                style={{
                  background: "var(--color-primary)",
                  color: "white",
                  margin: "1rem",
                }}
                onClick={() => addCondition()}
              >
                Add condition
              </Button>
            </Fragment>
          )}
          <br />
          {formData.conditions[0].name !== "" && <h4>Present Conditions:</h4>}

          {formData.conditions.map((condition: Condition, index: number) => {
            return (
              <VariantItem>
                {condition.name !== "" && (
                  <h4>
                    {index + 1}. {condition.name} - {condition.type}
                  </h4>
                )}
              </VariantItem>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button color="primary" type="submit">
            Erstellen
          </Button>
        </DialogActions>
      </form>
    </Fragment>
  );
};

const VariantItem = styled.p`
  font-weight: 500;
  font-size: 1.4rem;
  font-family: Montserrat;
  line-height: 2rem;
`;

export default NewContractModal;
