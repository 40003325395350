import {
  SET_INSURANCES,
  SET_CONTRACTS,
  SET_VARIANTS,
  SET_CURRENT_INSURANCE,
  SET_CURRENT_CONTRACT,
  SET_CURRENT_VARIANT,
  CREATE_INSURANCE,
  CREATE_CONTRACT,
  CREATE_VARIANT,
  UPDATE_INSURANCE,
  UPDATE_CONTRACT,
  UPDATE_VARIANT,
  DELETE_INSURANCE,
  DELETE_CONTRACT,
  DELETE_VARIANT,
} from "../types";

import { AdminState, Action } from "../../definitions";

const Reducer = (state: AdminState, action: Action) => {
  switch (action.type) {
    case SET_INSURANCES: {
      return {
        ...state,
        insurances: action.payLoad,
      };
    }
    case SET_CONTRACTS: {
      return {
        ...state,
        contracts: action.payLoad,
        variants: [],
      };
    }
    case SET_VARIANTS: {
      return {
        ...state,
        variants: action.payLoad,
      };
    }

    case SET_CURRENT_INSURANCE: {
      return {
        ...state,
        currentInsurance: action.payLoad,
        currentContract: null,
        variants: [],
      };
    }

    case SET_CURRENT_CONTRACT: {
      return {
        ...state,
        currentContract: action.payLoad,
        variants: [],
      };
    }

    case SET_CURRENT_VARIANT: {
      let variants = state.variants;
      let current = variants.filter((variant) => variant._id.toString() === action.payLoad);
      return {
        ...state,
        currentVariant: current[0],
      };
    }

    case CREATE_INSURANCE: {
      return {
        ...state,
        insurances: action.payLoad,
      };
    }

    case CREATE_CONTRACT: {
      return {
        ...state,
        contracts: action.payLoad,
      };
    }

    case CREATE_VARIANT: {
      return {
        ...state,
        variants: action.payLoad,
      };
    }

    case UPDATE_INSURANCE: {
      return {
        ...state,
        insurances: action.payLoad,
      };
    }

    case UPDATE_CONTRACT: {
      return {
        ...state,
        contracts: action.payLoad,
      };
    }

    case UPDATE_VARIANT: {
      let variants = state.variants;
      // map and update
      let updatedVariants = variants.map((variant) => {
        if (variant._id !== action.payLoad._id) {
          return variant;
        } else {
          return action.payLoad;
        }
      });
      return {
        ...state,
        variants: updatedVariants,
      };
    }

    case DELETE_INSURANCE: {
      return {
        ...state,
        insurances: action.payLoad,
        currentInsurance: null,
      };
    }

    case DELETE_CONTRACT: {
      return {
        ...state,
        contracts: action.payLoad,
      };
    }

    case DELETE_VARIANT: {
      return {
        ...state,
        variants: action.payLoad,
      };
    }

    // fallback - change nothing
    default: {
      return {
        ...state,
      };
    }
  }
};

export default Reducer;
