import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Typography,
  TextField,
  Divider,
} from "@material-ui/core";
// Auth context
import AuthContext from "../../context/auth/authContext";
import { useSnackBars } from "../../utils/hooks/snackbar/useSnackbar";

export default function LoginForm() {
  const authContext = useContext(AuthContext);
  const { login } = authContext;
  const classes = useStyles();

  // States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { addAlert } = useSnackBars();

  const sendFormData = async () => {
    try {
      const details = {
        email: "",
        password: "",
      };

      if (email) details.email = email;

      if (password) details.password = password;

      await login(details);
    } catch (error: any) {
      addAlert({
        type: "error",
        message: `${error.response.status}: ${error.response.data.message}`,
      });
    }
  };

  return (
    <FlexContainer>
      <Card className={classes.card}>
        <CardHeader title="Administration" />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            In das Cockpit einloggen
          </Typography>

          <form
            method="POST"
            onSubmit={(e) => {
              e.preventDefault();
              sendFormData();
            }}
          >
            <TextField
              required
              type="email"
              id="standard-required"
              label="E-Mail Addresse"
              className={classes.textField}
              margin="normal"
              autoComplete="on"
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              required
              id="standard-password-input"
              label="Passwort"
              className={classes.textField}
              type="password"
              autoComplete="current-password"
              margin="normal"
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              fullWidth
              variant="contained"
              type="submit"
              size="medium"
              className={classes.loginButton}
            >
              Login
            </Button>
          </form>
        </CardContent>
      </Card>
    </FlexContainer>
  );
}

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    opacity: 0.95,
    filter: "back",
  },
  loginButton: {
    background: "var(--color-secondary)",
    color: "white",
    margin: "1.5rem 0 0 0",
    "&:hover": {
      background: "var(--color-primary)",
    },
  },
  textField: {
    marginLeft: "1rem",
    marginRight: "1rem",
    width: 300,
  },
  buttonContainer: {
    padding: ".5rem",
  },
});

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  background-image: linear-gradient(135deg, #0522a3ac, #00000044),
    url("https://images.unsplash.com/photo-1436491865332-7a61a109cc05?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1653&q=80");
  background-size: cover;
  background-position: center center;
  min-height: 70vh;
  flex: 1;
`;
