import { Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Home, SecurityOutlined, Assignment } from "@material-ui/icons/";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../assets/img/GUTZWEILER-logo-r-ohneSlogan-614x156.png";
import AppContext from "../../context/app/appContext";
import AuthContext from "../../context/auth/authContext";

const AppDrawer = () => {
  const appContext = useContext(AppContext);
  const { drawerOpen, toggleDrawer } = appContext;

  const authContext = useContext(AuthContext);
  const { isAuthenticated } = authContext;

  return (
    <SwipeableDrawer
      disableBackdropTransition
      onClose={() => toggleDrawer?.()}
      onOpen={() => toggleDrawer?.()}
      open={!!drawerOpen}
    >
      <LogoHeader>
        <img src={Logo} alt="logo" />
      </LogoHeader>
      <List>
        <Link to="/">
          <ListItem button>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary={"Versicherungen"} />
          </ListItem>
        </Link>
        {isAuthenticated && (
          <>
            <Link to="/admin">
              <ListItem button>
                <ListItemIcon>
                  <SecurityOutlined />
                </ListItemIcon>
                <ListItemText primary={"Admin panel"} />
              </ListItem>
            </Link>
            <Link to="/admin/pdf">
              <ListItem button>
                <ListItemIcon>
                  <Assignment />
                </ListItemIcon>
                <ListItemText primary={"PDF Texte"} />
              </ListItem>
            </Link>
          </>
        )}
      </List>
      <Divider />
    </SwipeableDrawer>
  );
};

const LogoHeader = styled.div`
  width: 25rem;
  padding: 2rem;

  border-bottom: 1px solid #dedede;

  & img {
    width: 100%;
  }
`;
export { AppDrawer };
