import React, { useContext } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import {
  EuroOutlined,
  PersonOutline,
  ScheduleOutlined,
  TrendingUpOutlined,
} from "@material-ui/icons";
import FormContext from "../../../../context/form/formContext";
import { formatDate, isValidDate } from "../../../../utils/services/date-time";
import { formatToEuro } from "../../../../utils/helpers-format";

const Summary = () => {
  const formContext = useContext(FormContext);
  const {
    contractDuration,
    underlying,
    deathBenefit,
    progressionValue,
    startDate,
    firstName,
    lastName,
    dob,
  } = formContext;
  return (
    <Container maxWidth="md">
      <Card>
        <CardHeader title="Zusammenfassung der Auswahl" />
        <CardContent>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PersonOutline />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Personendaten"
                secondary={`${firstName} ${lastName}, geboren am ${
                  dob && isValidDate(dob) && formatDate(dob)
                }`}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ScheduleOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Laufzeit"
                secondary={`${contractDuration} ${contractDuration === 1 ? "Jahr" : "Jahre"}`}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <EuroOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Grundsumme Invalidität" secondary={formatToEuro(underlying)} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <TrendingUpOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Progression" secondary={`${progressionValue?.toFixed(0)} %`} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <EuroOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Leistung im Todesfall"
                secondary={formatToEuro(deathBenefit)}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ScheduleOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Versicherungsbeginn"
                secondary={`${startDate && isValidDate(startDate) && formatDate(startDate)}`}
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Summary;
