import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { OptionCardType } from "../../../definitions/";
import { Link } from "react-router-dom";
import AppContext from "../../../context/app/appContext";

import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Chip,
  Typography,
  Button,
} from "@material-ui/core";
import FormContext from "../../../context/form/formContext";

const OptionCard = (props: OptionCardType) => {
  const { title, imageURL, description, link, insuranceId, subtitle, variantId } = props;

  const classes = useStyles();

  const appContext = useContext(AppContext);
  const { setInsurance, setCurrentContract } = appContext;

  const formContext = useContext(FormContext);
  const { setDisplayedVariantID, setStep } = formContext;

  const handleInsurance = (e: React.MouseEvent<HTMLAnchorElement>, insurance: string) => {
    // set current variant id
    setDisplayedVariantID?.(variantId);
    setStep?.(0);
    setInsurance?.(insurance);
    setCurrentContract?.(insuranceId);
  };
  return (
    <Link style={{ textDecoration: "none" }} to={link} onClick={(e) => handleInsurance(e, title)}>
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="180"
            image={imageURL}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography variant="h5" component="h2">
              {title}{" "}
              {subtitle && <Chip size="small" label={subtitle} clickable color="secondary" />}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary">
            Zum Rechner
          </Button>
        </CardActions>
      </Card>
    </Link>
  );
};

OptionCard.propTypes = {};

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    "& .MuiCardMedia-img": {
      objectFit: "contain",
    },
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiCardActions-root": {
      flex: 1,
      alignItems: "flex-end",
    },
  },
});
export default OptionCard;
