import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import Logo from "../../assets/img/GUTZWEILER-logo-r-ohneSlogan-614x156.png";
const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <Branding>
          <img src={Logo} alt="Logo" />
        </Branding>
        <Grid container alignItems="baseline" justifyContent="flex-start">
          <GridItem item md={3}>
            <Headline>Kontaktdaten</Headline>
            <FooterBlock>
              <p>
                <strong>Adrian Gutzweiler</strong> <br /> Versicherungsmakler GmbH & CO. KG <br />{" "}
                Luftfahrt- und Flugzeug- versicherungen
              </p>
              <p>
                Johannes-Brahms-Straße 3 <br />
                79189 Bad Krozingen <br />
                Tel. 0761 888662-0 <br />
                Fax 0761 888662-22 <br />
                info(at)gutzweiler.de
              </p>
            </FooterBlock>
          </GridItem>
          <GridItem item md={3}>
            <Headline>So erreichen Sie uns</Headline>

            <FooterBlock>
              <p>
                Montag bis Donnerstag: 09:00 bis 12:00 Uhr und 13:00 bis 16:00 Uhr Freitag: 09:00
                bis 12:00 Uhr
              </p>
              <p>
                Bei einem akuten Schadensfall Tel. 0761 888662-11 (außerhalb der Bürozeiten) oder{" "}
                <a
                  href="https://luftfahrtversicherung24.de/online-schadensmeldung"
                  rel="noopener noreferrer"
                >
                  zur Online Schadensmeldung
                </a>
              </p>
            </FooterBlock>
          </GridItem>
          <GridItem item md={3}>
            <Headline>Bewertungen</Headline>
            <FooterBlock>
              <a
                href="https://www.provenexpert.com/adrian-gutzweiler-versicherungsmakler-gmbh-co-kg/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget"
                title="Kundenbewertungen &amp; Erfahrungen zu Adrian Gutzweiler Versicherungsmakler GmbH & Co. KG. Mehr Infos anzeigen."
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://images.provenexpert.com/34/75/4ab850d51597f5953fbe5c245ac3/widget_portrait_180_de_0.png"
                  alt="Erfahrungen &amp; Bewertungen zu Adrian Gutzweiler Versicherungsmakler GmbH & Co. KG"
                  width="180"
                  height="216"
                />
              </a>
              <a id="pe_badge_hwhzgmxb" href="/" target="_blank" rel="noopener noreferrer">
                {" "}
              </a>
              <a id="pe_badge_mabqcrex" href="/" target="_blank" rel="noopener noreferrer">
                {" "}
              </a>
            </FooterBlock>
          </GridItem>
          <GridItem item md={3}>
            <Headline>Rechtliches</Headline>

            <FooterBlock>
              <a href="https://luftfahrtversicherung24.de/impressum" rel="noopener noreferrer">
                Impressum
              </a>
              <a
                href="https://luftfahrtversicherung24.de/erstinformationen"
                rel="noopener noreferrer"
              >
                Erstinformation
              </a>
              <a href="https://luftfahrtversicherung24.de/datenschutz" rel="noopener noreferrer">
                Datenschutz
              </a>
              <a
                href="https://luftfahrtversicherung24.de/allgemeine-geschaeftsbedingungen"
                rel="noopener noreferrer"
              >
                AGB
              </a>
              <a href="https://luftfahrtversicherung24.de/bildnachweis" rel="noopener noreferrer">
                Bildnachweis
              </a>
              <br />
            </FooterBlock>
            <img
              width="220"
              src="https://luftfahrtversicherung24.de/assets/uploads/2020/03/vema_white.svg"
              alt="logo"
            ></img>
          </GridItem>
        </Grid>
      </FooterContent>
      <FooterBottom>
        &copy; {new Date().getFullYear()} GUTZWEILER Versicherungsmakler GmbH & CO. KG
      </FooterBottom>
    </FooterContainer>
  );
};

const Branding = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
  width: 100%;
  & img {
    width: 25rem;
    filter: brightness(10);
  }
  margin-bottom: 5rem;
`;

const FooterContainer = styled.footer`
  min-height: 20vh;
  background: var(--color-grey--light);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterContent = styled.div`
  padding: 5rem;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  max-width: 125rem;
`;

const FooterBottom = styled.div`
  height: 5rem;
  width: 100%;
  background: var(--color-grey--dark);

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  font-size: 1.2rem;
`;

const FooterBlock = styled.div`
  color: white;
  font-size: 1.4rem;
  color: white;

  display: flex;
  flex-direction: column;

  a {
    color: white;
  }
`;

const Headline = styled.p`
  color: white;
  font-weight: 600;
  letter-spacing: 0.1rem;
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
`;

const GridItem = styled(Grid)`
  padding: 1rem;
`;

export default Footer;
