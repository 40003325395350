import React, { Fragment, useContext, useState, useEffect } from "react";
import styled from "styled-components";
//Diaglogs
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { Condition, Options, CellTypes } from "../../definitions";
//Context
import AdminContext from "../../context/admin/adminContext";

// Utility
import confirmDelete from "../../utils/confirmDelete";

interface Props {
  currentVariant: any;
  handleClose: any;
}

const NewContractModal: React.FC<Props> = ({ currentVariant, handleClose }) => {
  /**
   * @description local state and global context vars
   */
  const adminContext = useContext(AdminContext);
  const { currentContract, updateVariant } = adminContext;

  const [currentCondition, setCurrentCondition] = useState("");

  const [optionObject, setOptionObject] = useState({
    _id: "",
    name: "",
    description: "",
    information: "",
    price: 0,
    type: CellTypes.Checkbox,
    selected: false,
    included: false,
    factor: 0.0,
    options: [],
    optionString: "",
  });

  let { name, description, information, price, type, selected, included, factor, optionString } =
    optionObject;

  const [formData, setFormData] = useState<any>({
    option: "",
    contract: currentContract?._id,
    contractName: currentContract?.name,
    imageUrl: "",
    conditions: [
      {
        name: "",
        description: "",
        information: "",
        price: 0,
        type: "",
        selected: false,
        included: false,
        factor: 0.0,
        options: [0],
      },
    ],
  });

  /**
   * Prefilling the form to make it easier for the user
   */
  useEffect(() => {
    if (currentVariant) {
      let { option, conditions, imageUrl } = currentVariant;
      setFormData((prev: any) => {
        return {
          ...prev,
          conditions,
          name,
          option,
          imageUrl,
        };
      });
    }
  }, [currentVariant]); //eslint-disable-line

  const editCondition = async () => {
    formData.conditions.forEach((condition: Condition) => {
      if (condition._id === currentCondition) {
        condition.name = optionObject.name;
        condition.price = optionObject.price;
        condition.description = optionObject.description;
        condition.information = optionObject.information;
        condition.included = optionObject.included;
        condition.type = optionObject.type;
        condition.selected = optionObject.selected;
        condition.factor = optionObject.factor;
        condition.options = optionObject.optionString?.split(",").map(Number)!;
      }
    });

    const _id = currentVariant._id;
    await updateVariant(_id, formData);
    return;
  };

  const addOrEditCondition = async () => {
    if (currentCondition !== "") {
      editCondition();
      window.alert("Konditionen wurden aktualisiert");
      return;
    }

    /**
     * this checks if the option name is allowed
     */
    const possibleValues: string[] = Object.values(Options);
    if (!possibleValues.includes(optionObject.name)) {
      alert("Dieser Wert ist bisher nicht zugelassen");
      return;
    }

    formData.conditions.push({
      name,
      description,
      information,
      price,
      type,
      selected,
      included,
      factor,
      options: optionString.split(",").map(Number),
    });

    const _id = currentVariant._id;
    await updateVariant(_id, formData);

    setOptionObject({
      ...optionObject,
      name: "",
      description: "",
      information: "",
      price: 0,
      type: CellTypes.Fix,
      selected: false,
      included: false,
      factor: 0.0,
      options: [],
      optionString: "",
    });
  };

  const removeCondition = async (_id: String) => {
    let confirm = confirmDelete();
    if (!confirm) {
      return;
    }

    formData.conditions = formData.conditions.filter((condition: Condition) => {
      return condition._id !== _id;
    });
    await updateVariant(currentVariant?._id, formData);
    return;
  };

  const setCurrentConditionOnClick = (condition: Condition) => {
    setCurrentCondition(condition._id);
    setOptionObject({
      ...optionObject,
      name: condition.name,
      description: condition.description,
      information: condition.information,
      price: condition.price,
      type: condition.type,
      selected: condition.selected!,
      included: condition.included!,
      factor: condition.factor!,
      optionString: condition.options?.join(",")!,
    });
  };

  const desetCurrentCondition = () => {
    setCurrentCondition("");
    setOptionObject({
      ...optionObject,
      name: "",
      description: "",
      information: "",
      price: 0,
      type: CellTypes.Fix,
      selected: false,
      included: false,
      factor: 0.0,
      options: [],
      optionString: "",
    });
  };

  const handleChange = (e: any) => {
    if (e.target) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleObjectChange = (e: any) => {
    if (e.target) {
      if (e.target.name === "selected" || e.target.name === "included") {
        setOptionObject({ ...optionObject, [e.target.name]: e.target.checked });
        return;
      }
      setOptionObject({ ...optionObject, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    await updateVariant(currentVariant?._id, formData);
    handleClose();
  };

  return (
    <Fragment>
      <form onSubmit={(e) => onSubmit(e)} method="POST">
        <DialogTitle id="form-dialog-title">Edit Variant</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            required
            name="option"
            label="Name der Vertragsvariante"
            value={formData.option}
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
          />
          <TextField
            margin="dense"
            required
            name="contract"
            label="Vertragstyp"
            type="text"
            fullWidth
            value={formData.contractName}
            disabled={true}
            onChange={(e) => handleChange(e)}
          />

          <TextField
            margin="dense"
            name="imageUrl"
            label="Logo URL"
            type="text"
            value={formData.imageUrl}
            fullWidth
            onChange={(e) => handleChange(e)}
          />

          <TextField
            select
            margin="dense"
            required={formData.conditions.length < 1}
            name="name"
            label="Kondition"
            type="text"
            value={name}
            fullWidth
            onChange={(e) => handleObjectChange(e)}
          >
            {Object.values(Options).map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            required={formData.conditions.length < 1}
            name="description"
            label="Beschreibung"
            type="text"
            value={description}
            fullWidth
            onChange={(e) => handleObjectChange(e)}
          />
          {type === CellTypes.Price && (
            <TextField
              margin="dense"
              required={formData.conditions.length < 1}
              name="price"
              label="Konditionspreis (falls vorhanden)"
              type="number"
              value={price}
              fullWidth
              onChange={(e) => handleObjectChange(e)}
            />
          )}
          {type === CellTypes.Information && (
            <TextField
              margin="dense"
              name="information"
              label="Anzugebende Informationen"
              type="text"
              value={information}
              fullWidth
              onChange={(e) => handleObjectChange(e)}
            />
          )}
          <FormControl required={formData.conditions.length < 1}>
            <InputLabel>Eingabeformat</InputLabel>
            <Select
              name="type"
              value={type}
              style={{ width: "20rem" }}
              onChange={(e) => handleObjectChange(e)}
            >
              <MenuItem value={CellTypes.Checkbox}>{CellTypes.Checkbox}</MenuItem>
              <MenuItem value={CellTypes.Dropdown}>{CellTypes.Dropdown}</MenuItem>
              <MenuItem value={CellTypes.Fix}>{CellTypes.Fix}</MenuItem>
              <MenuItem value={CellTypes.Information}>{CellTypes.Information}</MenuItem>
              <MenuItem value={CellTypes.Price}>Preisinformation</MenuItem>
            </Select>
          </FormControl>
          <br />
          {type === CellTypes.Fix && (
            <FormControlLabel
              control={
                <Checkbox
                  name="included"
                  value={included}
                  checked={included}
                  onChange={(e) => handleObjectChange(e)}
                />
              }
              label="Ist inbegriffen *"
            />
          )}
          {type === CellTypes.Checkbox && (
            <FormControlLabel
              control={
                <Checkbox
                  name="selected"
                  value={selected}
                  checked={selected}
                  onChange={(e) => handleObjectChange(e)}
                />
              }
              label="Ist inbegriffen *"
            />
          )}

          {type === CellTypes.Dropdown && (
            <Fragment>
              <TextField
                margin="dense"
                required
                name="factor"
                label="Konditionsfaktor (falls vorhanden)"
                type="number"
                value={factor}
                fullWidth
                onChange={(e) => handleObjectChange(e)}
              />
              <TextField
                margin="dense"
                required
                name="optionString"
                label="Optionswerte eingeben (mit Komma getrennt)"
                type="text"
                value={optionString}
                fullWidth
                onChange={(e) => handleObjectChange(e)}
              />
            </Fragment>
          )}

          <br />
          <span>
            <Button
              variant="outlined"
              style={{
                background: "var(--color-primary)",
                color: "white",
                margin: "1rem",
                width: "15rem",
              }}
              onClick={() => desetCurrentCondition()}
            >
              Clear
            </Button>
            <Button
              variant="outlined"
              style={{
                background: "darkblue",
                color: "white",
                margin: "1rem",
              }}
              onClick={() => addOrEditCondition()}
            >
              Kondition ändern / hinzufügen
            </Button>
          </span>
          <br />
          <h2>{formData.conditions[0].name !== "" && <h4>Bereits konfiguriert:</h4>}</h2>

          {formData.conditions.map((condition: Condition, index: number) => {
            return (
              <Fragment>
                <List dense={true}>
                  <ListItem>
                    <VariantItem>
                      {index + 1}. {condition.name} -{" "}
                      <ConditionType>{condition.type}</ConditionType>
                    </VariantItem>
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="edit">
                        <EditIcon onClick={() => setCurrentConditionOnClick(condition)} />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => removeCondition(condition._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Fragment>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button color="primary" type="submit">
            Aktualisieren
          </Button>
        </DialogActions>
      </form>
    </Fragment>
  );
};

const VariantItem = styled.p`
  font-weight: 500;
  font-size: 1.3rem;
  font-family: Montserrat;
  line-height: 1.3rem;
`;

const ConditionType = styled.span`
  background: #dedede;
  color: #222;
  border-radius: 0.3rem;
  letter-spacing: 0.1rem;
  padding: 0.35rem;
`;

export default NewContractModal;
