import React from "react";
import styled from "styled-components";

const Hero = () => {
  return (
    <Container>
      <HeroTitle>Willkommen bei Ihrem vergleichsrechner</HeroTitle>
    </Container>
  );
};

const Container = styled.section`
  height: 10vh;
  background: var(--color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center center;
  margin-bottom: 5rem;
`;

const HeroTitle = styled.h1`
  text-align: center;
  font-size: calc(1rem + 2vw);
  font-weight: 700;
  letter-spacing: 2px;
  color: white;
  text-transform: uppercase;
  text-shadow: 1px 2px 5px #111d;
  @media screen and (min-width: 1280px) {
    font-size: 3.75rem;
  }
`;
export default Hero;
