import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// Material-ui
import { Container } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Context and State
import AppState from "./context/app/AppState";
import FormState from "./context/form/FormState";
import AuthState from "./context/auth/AuthState";
import AdminState from "./context/admin/AdminState";
// Routing
import AdminRoute from "./routing/AdminRoute";
import LoginRoute from "./routing/LoginRoute";
// Layout
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
// Components
import Welcome from "./components/pages/welcome/Welcome";
import Calculator from "./components/calculator/Calculator";
import AdminLogin from "./components/auth/AdminLogin";
import AdminDashboard from "./components/admin/AdminDashboard";

import "./App.css";
import PdfEditor from "./components/admin/pdf-editor/PdfEditor";
import Thankyou from "./components/pages/thankyou/Thankyou";
import { SnackBarProvider } from "./utils/hooks/snackbar/SnackbarProvider";
import { AppDrawer } from "./components/layout/Drawer";
import { PdfSelection } from "./components/admin/pdf-editor/PdfSelection";
import { Instructors } from "./components/pages/insurances/instructors/Instructors";

const lfTheme = createTheme({
  palette: {
    primary: {
      main: "#d53e4a",
    },
    secondary: {
      main: "#1e7398",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: 24,
  },
});

function App() {
  return (
    <ThemeProvider theme={lfTheme}>
      <div className="App">
        <AppState>
          <AuthState>
            <SnackBarProvider>
              <Router>
                <Header />
                <AppDrawer />

                <Switch>
                  <Route exact path="/">
                    <FormState>
                      <Welcome />
                    </FormState>
                  </Route>

                  <Route exact path="/rechner">
                    <FormState>
                      <Container maxWidth="lg" style={{ padding: "2rem" }}>
                        <Calculator />
                      </Container>
                    </FormState>
                  </Route>

                  <Route exact path="/haftpflicht/einweiser">
                    <FormState>
                      <Container maxWidth="lg" style={{ padding: "2rem" }}>
                        <Instructors />
                      </Container>
                    </FormState>
                  </Route>

                  <Route exact path="/done">
                    <FormState>
                      <Thankyou />
                    </FormState>
                  </Route>

                  <AdminState>
                    <LoginRoute exact path="/admin" component={AdminLogin} />
                    <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} />
                    <AdminRoute exact path="/admin/pdf" component={PdfSelection} />
                    <AdminRoute exact path="/admin/pdf/editor/:initialKey" component={PdfEditor} />
                  </AdminState>
                </Switch>
              </Router>
            </SnackBarProvider>
          </AuthState>
          <Footer />
        </AppState>
      </div>
    </ThemeProvider>
  );
}

export default App;
