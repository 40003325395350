import "date-fns";
import React, { useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import FormContext from "../../../../context/form/formContext";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { DatePickerTypes } from "../../../../definitions";

export default function DateInput({
  label,
  initialDate,
  helperText,
  isFullWidth,
  name,
}: DatePickerTypes) {
  // The first commit of Material-UI
  const formContext = useContext(FormContext);
  const { setDateValue } = formContext;

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(initialDate || new Date());

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    setDateValue?.({ name, value: selectedDate });
  }, [selectedDate, name]); //eslint-disable-line

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justifyContent="space-around">
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          fullWidth={isFullWidth}
          format="dd.MM.yyyy"
          name={name}
          helperText={helperText}
          margin="normal"
          id="date-picker-inline"
          label={label}
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
