import React, { useState, useEffect } from "react";
import { isError } from "lodash";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import useLocalStorage from "../../utils/hooks/useLocalStorage";
import { useSnackBars } from "../../utils/hooks/snackbar/useSnackbar";

const EnterAgreement = ({ handleOpen, open }: { handleOpen: Function; open: boolean }) => {
  const [localStorageValue, setLocalStorage] = useLocalStorage("agreement", "");

  const [localStorageDate, setLocalStorageDate] = useLocalStorage("date", "");

  const [{ checked }, setState] = useState({
    checked: false,
    emailCheck: "",
  });

  const history = useHistory();

  // @ts-ignore
  const { addAlert } = useSnackBars();

  useEffect(() => {
    if (!localStorageValue) setLocalStorage("false");

    if (localStorageDate) {
      // the time is 2 days in milliseconds
      if (new Date(localStorageDate).getTime() < new Date().getTime() - 1000 * 60 * 60 * 24 * 2) {
        setLocalStorage("false");
        setLocalStorageDate(Date.now());
      }
    }
  }, [localStorageValue, setLocalStorage, localStorageDate, setLocalStorageDate]);

  const handleDecline = () => {
    handleOpen(false);

    setLocalStorage("false");

    history.push("/");
  };

  const handleAccept = async () => {
    try {
      if (!checked)
        throw new Error(
          "Bei Zustimmung, betätigen Sie bitte erst die Checkbox zur Datenverarbeitung"
        );

      handleOpen(false);

      setLocalStorage("true");

      setLocalStorageDate(Date.now());
    } catch (error: any) {
      if (isError(error)) {
        addAlert?.({ type: "error", message: error.message });
      }
    }
  };

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setState((prev) => ({ ...prev, checked: !prev.checked }));
  };

  return (
    <>
      {localStorageValue && localStorageValue === "false" ? (
        <Dialog maxWidth="lg" open={open}>
          <DialogTitle>Bevor Sie fortfahren:</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Der nachfolgende Angebotsprozess beinhaltet den Versand einiger PDF Dateien via
              E-Mail. Um einen reibungslosen Ablauf zu gewähren, benötigen wir hierzu eine
              Kontakt-E-Mail-Adresse sowie weitere persönliche Daten von Ihnen. Aufgrund
              gesetzlicher Vorgaben erfordert dies Ihre atkive Zustimmung. Bei Fragen oder
              Unklarheiten stehen wir Ihnen jederzeit gerne telefonisch unter der{" "}
              <b>0761 888662-0</b> zur Verfügung. Es werden keinerlei Daten von Ihnen gespeichert
              oder an Dritte weitergegeben.
            </DialogContentText>
            <FormControlLabel
              label="Ich stimme der Verarbeitung meiner Daten zum Zwecke einer Angebotsanfrage zu."
              control={
                <Checkbox
                  color="primary"
                  inputProps={{ "aria-label": "Zustimmung Datenschutz" }}
                  checked={checked}
                  onChange={(e) => handleCheckbox(e)}
                />
              }
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={() => handleDecline()}>
              Ablehnen
            </Button>
            <Button variant="contained" color="secondary" onClick={() => handleAccept()}>
              Akzeptieren
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default EnterAgreement;
