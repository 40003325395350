import { FormState } from "../../definitions";

interface FormValidation {
  message: string;
  isError: Boolean;
}

function validateInsuranceForm(form: Partial<FormState>): FormValidation {
  const formValidation = {
    isError: false,
    message: "",
  };

  if (!form.firstName) {
    formValidation.isError = true;
    formValidation.message = "Vorname muss ausgefüllt sein";
  }
  if (!form.lastName) {
    formValidation.isError = true;
    formValidation.message = "Nachname muss ausgefüllt sein";
  }
  if (!form.email) {
    formValidation.isError = true;
    formValidation.message = "Email muss ausgefüllt sein";
  }
  if (!form.address) {
    formValidation.isError = true;
    formValidation.message = "Adresse muss ausgefüllt sein";
  }
  if (!form.city) {
    formValidation.isError = true;
    formValidation.message = "Wohnort muss ausgefüllt sein";
  }
  if (!form.postcode) {
    formValidation.isError = true;
    formValidation.message = "Postleitzahl muss ausgefüllt sein";
  }
  if (!form.dob) {
    formValidation.isError = true;
    formValidation.message = "Geburtstag muss ausgefüllt sein";
  }
  if (!form.annualFee) {
    formValidation.isError = true;
    formValidation.message = "etwas ist schief gegangen. Bitte kontaktieren Sie uns persönlich";
  }
  if (!form.underlying) {
    formValidation.isError = true;
    formValidation.message = "Etwas ist schief gegangen. Bitte kontaktieren Sie uns persönlich";
  }
  if (!form.startDate) {
    formValidation.isError = true;
    formValidation.message = "Bitte wählen Sie ein Datum für den vertragsbeginn aus";
  }

  if (form.dob && isOlderThanSeventy(form)) {
    formValidation.isError = true;
    formValidation.message =
      "Die Vertragsbedingungen der Top Variante lassen ein Alter von 70+ nicht zu";
  }
  return formValidation;
}

function validateInstructorInsuranceForm(form: Partial<FormState>): FormValidation {
  const formValidation = {
    isError: false,
    message: "",
  };

  if (!form.firstName) {
    formValidation.isError = true;
    formValidation.message = "Vorname muss ausgefüllt sein";
  }
  if (!form.lastName) {
    formValidation.isError = true;
    formValidation.message = "Nachname muss ausgefüllt sein";
  }
  if (!form.email) {
    formValidation.isError = true;
    formValidation.message = "Email muss ausgefüllt sein";
  }

  return formValidation;
}

function isOlderThanSeventy(form: Partial<FormState>): Boolean {
  if (form.dob) {
    const selectedVariant = form.currentVariants?.find(
      (variant) => variant._id === form.displayedVariantID
    );
    const isTopVariant = selectedVariant?.option === "Top";

    if (isTopVariant) {
      const date = new Date();

      const years = date.getFullYear();
      const birthDate = form.dob.getFullYear();

      return years - birthDate > 70;
    }
  }
  return false;
}

export { validateInsuranceForm, validateInstructorInsuranceForm };
