/**
 * @description this file resolves which calculation model is used based on which insurance Type was chosengit
 */
import {
  Insurance,
  Condition,
  ContractVariantsAccident,
  Options,
  CellTypes,
  Contracts,
} from "../../definitions";
import { progressionListBase, progressionListTop } from "../structures/map.progression";
import { LIFE_INSURANCE_FACTOR_BASE, LIFE_INSURANCE_FACTOR_TOP } from "../structures/constants";

export function getAccidentTableSum(
  insurances: Array<Insurance>,
  displayedVariantID: string,
  duration?: number
): Array<number> {
  // get first index
  const insurance: Insurance = insurances[0];
  const { variants, contractType } = insurance;
  const filtered = variants.filter((variant) => variant._id === displayedVariantID);
  let result: any[] = [];

  /**
   * calculation scheme for accident insurances
   * this uses 2 variable values, progression and base
   */
  if (contractType === Contracts.Unfallversicherung) {
    // factorization of new calculation values
    let progression = 0;
    let basis = 0;
    let lifeValue = 0;
    let additivValue = 0;

    // loop over the variants and extract relevant data into array
    filtered.forEach((rate) => {
      // reset values
      progression = 0;
      basis = 0;
      lifeValue = 0;
      additivValue = 0;

      rate.conditions.forEach((condition) => {
        if (!condition) {
          return;
        }
        // progression factor (percentage - used for multiplication)
        if (condition.name === Options.Progression) {
          progression = condition.price;
        }

        // base value (multiplied with progression factor)
        if (condition.name === Options.Grundsumme) {
          basis = condition.price;
        }

        // in case of death
        if (condition.name === Options.Todesfall && condition.type === CellTypes.Dropdown) {
          lifeValue = condition.price;
        }
        // normal value, should be added to the resulting factor value
        if (condition.type === CellTypes.Checkbox && condition.selected) {
          additivValue += condition.price;
        }
      });

      // gets a factorized values for a single progression
      let progressionFactor;

      switch (rate.option) {
        case ContractVariantsAccident.Basis: {
          progressionFactor = progressionListBase.get(progression);
          break;
        }

        case ContractVariantsAccident.Top: {
          progressionFactor = progressionListTop.get(progression);
          break;
        }
      }
      // per thousand
      let baseFactor = basis * 0.001 * progressionFactor;

      /**
       * If the client chooses to select death into the contract,
       * the factorization needs to take place regarding the contract variant
       * as each variant has a different factor
       */
      if (lifeValue && lifeValue > 0) {
        if (rate.option === ContractVariantsAccident.Basis) {
          baseFactor = baseFactor + lifeValue * 0.001 * LIFE_INSURANCE_FACTOR_BASE;
        }

        if (rate.option === ContractVariantsAccident.Top) {
          baseFactor = baseFactor + lifeValue * 0.001 * LIFE_INSURANCE_FACTOR_TOP;
        }
      }

      /**
       * The contracts can be choosen in different time ranges (such as 1y and 3y)
       * based on that discounts can be given or refused
       */
      if (duration && duration === 3) {
        baseFactor = baseFactor * 0.9;
      }

      /**
       * current Taxes in germany are 19%, which needs to be included in the multiplication
       */
      const baseTaxed = baseFactor * 1.19;

      result.push(baseTaxed + additivValue || 0);
    });

    return result;

    //calculation for different types
  } else {
    result = variants.map((rate) => {
      return rate?.conditions?.reduce((accumulator: any, current: Condition) => {
        // checkbox is checked, the add
        if (current.type === CellTypes.Checkbox) {
          return current.selected ? accumulator + current.price : accumulator;
        }
        // dropdown selector, handle the option
        else if (current.type === CellTypes.Dropdown) {
          return accumulator + current.price * current.factor!;
        }
        // type is "fix" - so no additional logic
        else if (current.type === CellTypes.Fix) {
          return accumulator + current.price;
        }

        return 0;
      }, 0);
    });
  }
  return result;
}
