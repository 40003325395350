import React from "react";
import { TableCell } from "@material-ui/core";
import { Condition } from "../../../../definitions";
import CheckIcon from "@material-ui/icons/CheckRounded";
import Cross from "@material-ui/icons/ClearRounded";

const FixedCell = (cell: Condition) => {
  const { included } = cell;

  return (
    <TableCell>{included ? <CheckIcon color="secondary" /> : <Cross color="primary" />}</TableCell>
  );
};

export default FixedCell;
