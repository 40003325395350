function getAxiosJsonConfig() {
  return {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      Accept: "application/json",
    },
    validateStatus: (status: Number) => {
      if (status >= 200 && status < 300) {
        return true;
      } else {
        if (status === 401) {
          window.localStorage.clear();
        }
        return false;
      }
    },
  };
}

export default getAxiosJsonConfig;
