/**
 * @description: generated a url encoded form string to pass
 * in the body of the https request
 * @param details values in object
 */

function createFormBody(details: any) {
  let body = [];

  for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    body.push(encodedKey + "=" + encodedValue);
  }
  return body.join("&");
}
export default createFormBody;
