import React, { useEffect, useContext } from "react";
import { Grid, Container, CircularProgress } from "@material-ui/core";
import { useSpring, animated } from "react-spring";

import Logo from "../../../assets/img/GUTZWEILER-logo-r-ohneSlogan-614x156.png";

import OptionCard from "./OptionCard";
import Hero from "./Hero";
import FormContext from "../../../context/form/formContext";
import { MainTitle } from "../../styled/Typography";

const Welcome = () => {
  const formContext = useContext(FormContext);
  const { getContractTypes, insurances, loading, setLoading } = formContext;

  // spring styles for animating the cards in the home view
  const styles = useSpring({
    to: async (next, cancel) => {
      await next({ transform: "translate3d(0,0,0)", opcaity: 1 });
    },
    from: { transform: "translate3d(0,-20rem,0)" },
  });

  // fetch the contract types
  useEffect(() => {
    try {
      setLoading?.(true);

      const getInsurances = async () => {
        getContractTypes && (await getContractTypes());
      };

      getInsurances();
    } catch (error) {
      console.log(error);
    }

    return;
  }, []); //eslint-disable-line

  return (
    <>
      <Hero />
      <Container style={{ paddingBottom: "10rem" }}>
        <Grid container justifyContent="center" alignItems="center" component="main" spacing={5}>
          <Grid xs={12} item>
            <MainTitle>Nach was suchen Sie ?</MainTitle>
          </Grid>

          <div>{!!loading && <CircularProgress />}</div>

          <Grid xs={12} item>
            <Grid container spacing={4} justifyContent="center" alignItems="stretch">
              {insurances?.map((insurance) =>
                insurance.variants.map((variant) => (
                  <Grid item key={variant._id}>
                    <animated.div style={{ ...styles, height: "100%" }}>
                      <OptionCard
                        title={insurance?.name}
                        subtitle={variant?.option}
                        imageURL={variant?.imageUrl || ""}
                        description={`Kalkulieren Sie hier die Konditionen zu der Piloten-Unfallversicherung in der Version ${variant?.option}`}
                        link="rechner"
                        insuranceId={insurance._id}
                        variantId={variant._id}
                      />
                    </animated.div>
                  </Grid>
                ))
              )}
              <Grid item>
                <animated.div style={styles}>
                  <OptionCard
                    title={"Haftpflichtversicherung für Fluglehrer & Einweiser"}
                    subtitle={""}
                    imageURL={Logo}
                    description={`Hier aus verschiedenen Deckungssummen wählen`}
                    link="/haftpflicht/einweiser"
                    insuranceId={"einweiser"}
                    variantId={"einweiser"}
                  />
                </animated.div>
              </Grid>
            </Grid>
            {/* <Grid container spacing={5} justifyContent="center" alignItems="center"> */}
            {/* </Grid> */}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Welcome;
