import React, { Fragment, useState, useEffect, useContext } from "react";
// Material UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Dialog, Button } from "@material-ui/core";
// Context
import AdminContext from "../../context/admin/adminContext";
// Styling
import { makeStyles } from "@material-ui/core/styles";
import Title from "./Title";
// Icons
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// Modals
import NewInsuranceModal from "./NewInsuranceModal";
import EditInsuranceModal from "./EditInsuranceModal";
// Utility
import confirmDelete from "../../utils/confirmDelete";

const Insurances: React.FC = () => {
  const classes = useStyles();

  const adminContext = useContext(AdminContext);
  const { insurances, getInsurances, getCurrentInsurance, deleteInsurance } = adminContext;

  const [newInsuranceModal, setNewInsuranceModal] = useState(false);
  const [editInsuranceModal, setEditInsuranceModal] = useState(false);

  const setCurrentInsuranceClick = (_id: String) => {
    getCurrentInsurance(_id);
  };

  const editInsurance = (_id: string) => {
    getCurrentInsurance(_id);
    setEditInsuranceModal(true);
  };

  const removeInsurance = (_id: String) => {
    const confirm = confirmDelete();

    if (confirm) {
      deleteInsurance(_id);
    }
  };

  const handleClose = () => {
    setNewInsuranceModal(false);
    setEditInsuranceModal(false);
  };

  useEffect(() => {
    getInsurances();
  }, []); //eslint-disable-line

  return (
    <Fragment>
      <Title>Versicherungsanbieter</Title>
      {insurances?.length === 0 ? (
        <h2>No insurances found</h2>
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: "center" }}>S.No.</TableCell>
              <TableCell style={{ textAlign: "center" }}>Logo</TableCell>
              <TableCell style={{ textAlign: "center" }}>Insurance Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <br />
          </TableHead>
          <TableBody>
            {insurances?.map((insurance, index) => {
              return (
                <Fragment>
                  <TableRow key={index}>
                    <TableCell style={{ textAlign: "center" }}>{index + 1}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <img src={insurance.logoURL} height="40" alt="insuranceIMG" />
                    </TableCell>
                    <TableCell
                      className={classes.nameField}
                      onClick={() => setCurrentInsuranceClick(insurance._id)}
                    >
                      {insurance.name}
                    </TableCell>
                    <TableCell>
                      <EditIcon
                        className={classes.icon}
                        onClick={() => editInsurance(insurance._id)}
                      />
                    </TableCell>
                    <TableCell>
                      <DeleteIcon
                        className={classes.icon}
                        onClick={() => removeInsurance(insurance._id)}
                      />
                    </TableCell>
                  </TableRow>
                  <br />
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      )}
      <Button
        style={{ margin: "2rem" }}
        variant="contained"
        color="primary"
        onClick={() => setNewInsuranceModal(true)}
      >
        Neue Versicherung anlegen
      </Button>
      <Dialog open={newInsuranceModal} onClose={handleClose} aria-labelledby="Open Insurance Form">
        <NewInsuranceModal handleClose={handleClose} />
      </Dialog>
      <Dialog
        open={editInsuranceModal}
        onClose={handleClose}
        aria-labelledby="Open Edit Insurance Form"
      >
        <EditInsuranceModal handleClose={handleClose} />
      </Dialog>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  nameField: {
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#f2f2f2",
      cursor: "pointer",
    },
  },
  icon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default Insurances;
