import React from "react";
import { TableCell } from "@material-ui/core";
import { Condition } from "../../../../definitions";

const PriceCell = (cell: Condition) => {
  const { price } = cell;

  return (
    <TableCell>
      <span>{`${price.toLocaleString("de-DE")}€`}</span>
    </TableCell>
  );
};

export default PriceCell;
