/**
 * in case a value does not exist in another variant
 * of the current contract type, the basecell (eg. empty checkbox)
 * can be included
 */

import { nanoid } from "nanoid";
import { CellTypes, Condition } from "../../definitions";
export const generateBaseCell = (): Condition => {
  return {
    _id: nanoid(),
    name: "",
    price: 0,
    type: CellTypes.Fix,
    information: "",
    description: "",
    included: false,
  };
};
